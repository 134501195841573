import { Controller, useForm } from 'react-hook-form';
import { Box, Grid, TextField, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useOtp } from 'queries/authentication/Otp';
import { useRegister } from 'queries/authentication/Register';
import { mobileRegex, convertArabicNumbersToEnglish } from 'utilities';
import LOGIN from 'assests/images/login.svg';

type ILoginForm = {
  countryCode: string;
  mobileNumber: string;
};

type ILogin = {
  openTotpDialog: () => void;
  isTotpDialogOpen: boolean;
};

export function Login({ openTotpDialog, isTotpDialogOpen }: ILogin) {
  const location = useLocation();

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<ILoginForm>({
    defaultValues: {
      mobileNumber: '',
      countryCode: '+966',
    },
  });

  const otp = useOtp();
  const register = useRegister();

  function onSubmit() {
    otp.mutate(
      {
        mobileNumber: getValues().countryCode.concat(
          convertArabicNumbersToEnglish(getValues().mobileNumber),
        ),
      },
      {
        onSuccess: () => {
          sessionStorage.setItem(
            'loggingMobileNumber',
            getValues().countryCode.concat(
              convertArabicNumbersToEnglish(getValues().mobileNumber),
            ),
          );

          if (!isTotpDialogOpen) {
            openTotpDialog();
          }
        },
      },
    );
  }

  useEffect(() => {
    if (
      otp.error &&
      (otp.error.response?.status === 400 ||
        otp.error.response?.status === 404) &&
      !register.isSuccess &&
      !register.isLoading
    ) {
      register.mutate(
        {
          mobileNumber: getValues().countryCode.concat(
            convertArabicNumbersToEnglish(getValues().mobileNumber),
          ),
        },
        {
          onSuccess: () => {
            otp.mutate(
              {
                mobileNumber: getValues().countryCode.concat(
                  convertArabicNumbersToEnglish(getValues().mobileNumber),
                ),
              },
              {
                onSuccess: () => {
                  sessionStorage.setItem(
                    'loggingMobileNumber',
                    getValues().countryCode.concat(
                      convertArabicNumbersToEnglish(getValues().mobileNumber),
                    ),
                  );

                  if (!isTotpDialogOpen) {
                    openTotpDialog();
                  }
                },
              },
            );

            if (!isTotpDialogOpen && otp.isSuccess) {
              openTotpDialog();
            }

            sessionStorage.setItem(
              'loggingMobileNumber',
              getValues().countryCode.concat(
                convertArabicNumbersToEnglish(getValues().mobileNumber),
              ),
            );
          },
        },
      );

      otp.reset();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getValues, otp.error, register.isLoading, register.isSuccess]);

  useEffect(() => {
    sessionStorage.setItem('loginRedirectUrl', location.pathname);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let mobileNumberErrorText = ' ';

  if (errors.mobileNumber?.type === 'required') {
    mobileNumberErrorText = errors.mobileNumber?.message as string;
  } else if (errors.mobileNumber?.type === 'pattern') {
    mobileNumberErrorText = errors.mobileNumber?.message as string;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        pt={3}
        pb={5}
        px={3}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{ minWidth: { md: '880px', sm: '100%' } }}
      >
        <Grid
          className="about_first_section"
          display="grid"
          width={1}
          gap="2%"
          sx={{
            gridTemplateColumns: { xs: '1fr', sm: '60% 40%' },
            padding: { xs: '0', sm: '0' },
          }}
        >
          <Box
            className="pt_0 login_box"
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Typography sx={{ mt: 3, mb: 8 }} className="login_head">
              تسجيل الدخول
            </Typography>
            <Grid
              width={1}
              display="grid"
              alignItems="center"
              sx={{
                mb: 6,
                gridTemplateColumns: { xs: '70% auto', sm: '60% auto' },
              }}
              gap={2}
            >
              <Controller
                name="mobileNumber"
                control={control}
                rules={{
                  required: { value: true, message: 'يجب ادخال رقم جوال' },
                  pattern: {
                    value: mobileRegex,
                    message: 'رقم الهاتف يجب ان يكون رقم سعودي صحيح',
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="standard"
                    label="رقم الجوال '5XXXXXXX'"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ inputMode: 'numeric' }}
                    error={!!errors.mobileNumber}
                    helperText={mobileNumberErrorText}
                    className="phone_input"
                  />
                )}
              />
              <Controller
                name="countryCode"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled
                    variant="standard"
                    label="مفتاح الدولة"
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.countryCode}
                    helperText=" "
                    className="phone_input"
                  />
                )}
              />
            </Grid>

            <LoadingButton
              sx={{ width: '100%' }}
              variant="contained"
              loadingPosition="start"
              loading={register.isLoading || otp.isLoading}
              type="submit"
              className="orangeBtn contained"
            >
              تسجيل الدخول
            </LoadingButton>
          </Box>
          <Box
            className="pt_0"
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <img
              src={LOGIN}
              alt=""
              style={{
                width: '100%',
              }}
            />
          </Box>
        </Grid>
      </Box>
    </form>
  );
}
