import Color from 'color';
import ColorString from 'color-string';

export function getShadowColor(color: any, alpha = 0.25) {
  return ColorString.to.rgb(Color(color).alpha(alpha).rgb().array());
}

/**
 * Lighten a color by a alpha degree
 * @param color string
 * @param alpha number
 */
export function lightenColor(color: string, alpha = 0.2) {
  return ColorString.to.rgb(Color(color).alpha(alpha).rgb().array());
}

export function darkenColor(color: string, alpha = 0.2) {
  return ColorString.to.rgb(Color(color).darken(alpha).rgb().array());
}
