import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { IScrollToTopProps } from './ScrollToTop.types';

export function ScrollToTop(props: IScrollToTopProps) {
  const location = useLocation();
  const { children } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname, location.search]);

  return children;
}
