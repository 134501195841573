export enum IUpsertDealSteps {
  productServiceInfoStep = 0,
  oppositeAccountStep = 1,
  dealSummaryStep = 2,
}

export type IUpsertDeal = {
  activeStep?: number;
  onChangeActiveStep: (newActiveStep: number) => void;
};
