import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { IAccount } from 'types';

type IUserProfile = {
  data: IAccount;
};

export function useFetchUserProfileByMobileNumber(mobileNumber?: string) {
  const {
    error: err,
    data,
    isLoading,
    isSuccess,
    refetch,
  } = useQuery({
    queryKey: ['fetch-user-profile', mobileNumber],
    queryFn: ({ signal }) =>
      axios.get<IUserProfile>(
        `${
          import.meta.env.VITE_API_BASE_URL
        }/users/find-by-mobile-number/${mobileNumber}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
            'accept-language': 'ar',
          },
          signal,
        },
      ),
    retry: 2,
    refetchOnWindowFocus: false,
  });

  const error = err as AxiosError;

  return {
    error,
    data,
    isLoading,
    isSuccess,
    refetch,
  };
}
