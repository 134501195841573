import { Controller, useForm } from 'react-hook-form';
import { Grid, TextField, Button } from '@mui/material';
import React, { useEffect } from 'react';
import { ArrowBackIos } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { mobileRegex } from 'utilities/regex';
import { useFetchDeal } from 'queries/deals/FetchDeal';
import { IUpsertDeal } from '..';

export type IOppositeAccountStepForm = {
  name: string;
  mobileNumber: string;
  email: string | null;
};

export function OppositeAccountStep({ onChangeActiveStep }: IUpsertDeal) {
  const search = useLocation().search;
  const dealId = new URLSearchParams(search).get('dealId');
  const fetchDeal = useFetchDeal(dealId || '');

  const accountId = Cookies.get('accountId');
  const accountMobileNumber = Cookies.get('accountMobileNumber');

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IOppositeAccountStepForm>();

  function onSubmit(data: IOppositeAccountStepForm) {
    onChangeActiveStep(2);
    localStorage.setItem('upsert-deal-step-2', JSON.stringify(data));
  }

  let mobileNumberErrorText = ' ';

  if (errors.mobileNumber?.type === 'required') {
    mobileNumberErrorText = errors.mobileNumber?.message as string;
  } else if (errors.mobileNumber?.type === 'pattern') {
    mobileNumberErrorText = errors.mobileNumber?.message as string;
  } else if (errors.mobileNumber?.type === 'validate') {
    mobileNumberErrorText = errors.mobileNumber?.message as string;
  }

  useEffect(() => {
    const step2Info = localStorage.getItem('upsert-deal-step-2');
    const parsedStep2Info = JSON.parse(step2Info as string);

    if (step2Info) {
      reset({
        name: parsedStep2Info.name,
        email: parsedStep2Info.email,
        mobileNumber: parsedStep2Info.mobileNumber,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const step2Info = localStorage.getItem('upsert-deal-step-2');
    const deal = fetchDeal.data?.data.data;

    if (deal && !step2Info) {
      reset({
        name: deal[deal.buyer.id === accountId ? 'seller' : 'buyer'].name,
        email: deal[deal.buyer.id === accountId ? 'seller' : 'buyer'].email,
        mobileNumber: deal[
          deal.buyer.id === accountId ? 'seller' : 'buyer'
        ].mobileNumber.replace('+966', ''),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDeal.data]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid width={1} display="grid" gap={2} gridTemplateColumns="1fr">
        <Controller
          name="name"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              variant="filled"
              label="اسم الطرف الآخر للصفقة ( حقل مطلوب )"
              InputLabelProps={{ shrink: true }}
              placeholder="من فضلك, اكتب اسم الطرف الآخر"
              error={!!errors.name}
              helperText=" "
            />
          )}
        />

        <Controller
          name="mobileNumber"
          control={control}
          rules={{
            required: { value: true, message: 'يجب ادخال رقم جوال' },
            pattern: {
              value: mobileRegex,
              message: `رقم الهاتف يجب ان يكون رقم سعودي صحيح '5XXXXXXX'`,
            },
            validate: (mobileNumber) =>
              mobileNumber !== accountMobileNumber?.replace('+966', '') ||
              'رقم جوال الطرف الآخر نفس رقم جوالك',
          }}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              variant="filled"
              label="رقم جوال الطرف الآخر ( حقل مطلوب ) '5XXXXXXX'"
              InputLabelProps={{ shrink: true }}
              inputProps={{ inputMode: 'numeric' }}
              placeholder="من فضلك, اكتب رقم جوال الطرف الآخر '5XXXXXXX'"
              error={!!errors.mobileNumber}
              helperText={mobileNumberErrorText}
            />
          )}
        />

        <Controller
          name="email"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              variant="filled"
              label="البريد الإلكتروني للطرف الآخر ( حقل اختياري )"
              InputLabelProps={{ shrink: true }}
              placeholder=""
              error={!!errors.email}
              helperText="سيتم ارسال نسخة من الاتفاقية للطرف الآخر عبر البريد الالكتروني"
            />
          )}
        />
      </Grid>

      <Button
        sx={{ width: '100%', mt: '32px' }}
        variant="contained"
        type="submit"
      >
        الانتقال للخطوة الثانية
        <ArrowBackIos sx={{ fontSize: '10px', mx: '8px' }} />
      </Button>
    </form>
  );
}
