import { Box, Grid, Pagination, Typography, Button } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFetchUserDeals } from 'queries/deals/FetchUserDeals';
import PEOPLE_DEALS from 'assests/images/deals_2.svg';
import { BankNoteRoutes } from '../../../BankNoteRoutes';
import { DealCard } from './partials/DealCard';

export function Deals() {
  const [page, setPage] = useState(1);

  const navigate = useNavigate();

  const fetchUserDeals = useFetchUserDeals(page);

  function onUpsertDeal() {
    navigate(BankNoteRoutes.upsertDeal);
  }

  function onChange(event: React.ChangeEvent<unknown>, value: number) {
    setPage(value);
  }

  return (
    <Box
      sx={{
        px: 4,
        py: { xs: '26px', sm: 6 },
      }}
      className="Taps_content_box deals"
    >
      <Box className="content_border">
        {fetchUserDeals.data?.data.data &&
          fetchUserDeals.data?.data.data.length === 0 && (
            <Grid
              sx={{
                display: 'grid',
                gridGap: '20px',
                gridAutoFlow: 'row',
                justifyItems: 'center',
              }}
            >
              <img
                src={PEOPLE_DEALS}
                alt="deals-empty-state"
                className="dealsImg"
              />
              <Typography color="primary" className="dealsHead">
                ليس لديك اتفاقيات
              </Typography>
              <Typography color="primary" className="dealsSub">
                انشاء اتفاقية جديدة الآن
              </Typography>
              <Button
                variant="contained"
                className="orangeBtn contained dealsButton"
                fullWidth
                sx={{ my: { xs: 1, sm: 2 }, display: 'block' }}
                onClick={onUpsertDeal}
              >
                اتفاقية جديدة
              </Button>
            </Grid>
          )}
        {fetchUserDeals.data?.data.data &&
          fetchUserDeals.data?.data.data.length > 0 && (
            <>
              <Grid
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
                  gridGap: '10px',
                  alignItems: 'stretch',
                }}
              >
                {fetchUserDeals.data?.data.data.map((deal) => (
                  <DealCard key={deal.id} deal={deal} />
                ))}
              </Grid>

              <Grid
                sx={{
                  display: 'grid',
                  width: '100%',
                  justifyContent: 'end',
                  mt: 3,
                }}
              >
                <Pagination
                  count={Math.ceil(
                    fetchUserDeals.data?.data.meta.itemsCount / 10,
                  )}
                  page={page}
                  variant="outlined"
                  color="primary"
                  size="small"
                  onChange={onChange}
                />
              </Grid>
            </>
          )}
      </Box>
    </Box>
  );
}
