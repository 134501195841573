import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { IBankNoteError } from 'types';

type ILogin = {
  mobileNumber: string;
  otp: number;
};

type ILoginData = {
  accessToken: string;
};

type ILoginResponse = {
  data: ILoginData;
};

export function useLogin() {
  const { enqueueSnackbar } = useSnackbar();

  const {
    error: err,
    data,
    isLoading,
    isSuccess,
    mutate,
    reset,
  } = useMutation({
    mutationKey: ['login'],
    mutationFn: (request: ILogin) =>
      axios.post<ILoginResponse>(
        `${import.meta.env.VITE_API_BASE_URL}/auth/login-otp`,
        request,
        {
          headers: { 'accept-language': 'ar' },
        },
      ),
    onError: (requestError: AxiosError<IBankNoteError>) => {
      enqueueSnackbar(requestError?.response?.data?.message || '', {
        variant: 'error',
      });
    },
  });

  const error = err as AxiosError;

  return {
    error,
    data,
    isLoading,
    isSuccess,
    mutate,
    reset,
  };
}
