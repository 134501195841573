import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { IDeal } from 'types';

type IFetchDeal = {
  data: IDeal;
};

export function useFetchDeal(id?: string) {
  const {
    error: err,
    data,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: ['fetch-deal', id],
    queryFn: ({ signal }) =>
      axios.get<IFetchDeal>(
        `${import.meta.env.VITE_API_BASE_URL}/deals/${id}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
            'accept-language': 'ar',
          },
          signal,
        },
      ),
    staleTime: Infinity,
    enabled: !!id,
    retry: 3,
  });

  const error = err as AxiosError;

  return {
    error,
    data,
    isLoading,
    isSuccess,
  };
}
