import { Box, Grid, Link, Typography } from '@mui/material';
import FACEBOOK_ICON from 'assests/images/facebook.svg';
import INSTAGRAM_ICON from 'assests/images/instagram.svg';
import TWITTER_ICON from 'assests/images/twitter.svg';

export function Footer() {
  return (
    <>
      <Box
        display="grid"
        gridTemplateColumns="50% 50%"
        justifyContent="space-between"
        className="footer_box"
      >
        <Box className="text_left social_box">
          <Typography fontSize="14px" color="primary" marginTop={2}>
            تابعنا
          </Typography>

          <Grid
            display="grid"
            marginTop={2}
            gridTemplateColumns="repeat(3, 20px)"
            gap={2}
            alignItems="center"
          >
            <Link
              href="https://www.facebook.com/profile.php?id=100085262892258&mibextid=LQQJ4d"
              underline="none"
              target="_blank"
            >
              <img
                src={FACEBOOK_ICON}
                alt="facebook"
                style={{
                  width: '30px',
                  verticalAlign: 'text-top',
                }}
              />
            </Link>

            <Link
              href="https://twitter.com/usebanknote?s=11&t=u9DUG3xtS38_NqVEnF6n2g"
              underline="none"
              target="_blank"
            >
              <img
                src={TWITTER_ICON}
                alt="twitter"
                style={{
                  width: '30px',
                  verticalAlign: 'text-top',
                }}
              />
            </Link>

            <Link
              href="https://www.instagram.com/usebanknote/?igshid=YmMyMTA2M2Y%3D"
              underline="none"
              target="_blank"
            >
              <img
                src={INSTAGRAM_ICON}
                alt="instagram"
                style={{
                  width: '30px',
                  verticalAlign: 'text-top',
                }}
              />
            </Link>
          </Grid>
        </Box>

        <Box className="text_right email_box">
          <Typography fontSize="14px" color="primary" marginTop={2}>
            | <span className="pr-5 d_block">للتواصل</span>
          </Typography>
          <Typography fontSize="14px" color="primary">
            info@banknote.sa
          </Typography>
        </Box>
      </Box>

      <Typography
        width={1}
        className="footer_bar"
        fontSize="14px"
        marginTop={7}
        marginBottom={5}
        textAlign="center"
      >
        جميع الحقوق محفوظة لمنصة بنكنوت 2023
      </Typography>
    </>
  );
}
