import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactInputVerificationCode from 'react-input-verification-code';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import LOGO from 'assests/images/logo.svg';
import { useLogin } from 'queries/authentication/Login';
import { useFetchPersonalProfile } from 'queries/profile/FetchPersonalProfile';
import { BankNoteRoutes } from 'BankNoteRoutes';

export function Totp() {
  const location = useLocation();
  const navigate = useNavigate();
  const login = useLogin();
  const fetchPersonalProfile = useFetchPersonalProfile();
  const isUserLoggedIn = Cookies.get('token');

  const [code, setCode] = useState<string>();

  function onChange(value: string) {
    setCode(value);
  }

  function onSubmit(otp: string) {
    const loggingMobileNumber = sessionStorage.getItem('loggingMobileNumber');

    login.mutate(
      {
        mobileNumber: loggingMobileNumber as string,
        otp: Number(otp),
      },
      {
        onError: () => {
          setCode('');
        },
      },
    );
  }

  useEffect(() => {
    if (login.isSuccess) {
      Cookies.set('token', login.data?.data.data.accessToken as string);
      localStorage.setItem('isUserLoggedIn', 'true');
      sessionStorage.removeItem('loggingMobileNumber');

      fetchPersonalProfile.refetch().then(() => {});
    }
  }, [
    login.isSuccess,
    login.data?.data.data.accessToken,
    fetchPersonalProfile,
  ]);

  useEffect(() => {
    const loginRedirectUrl = sessionStorage.getItem('loginRedirectUrl');

    if (fetchPersonalProfile.isSuccess && isUserLoggedIn) {
      Cookies.set(
        'accountName',
        fetchPersonalProfile.data?.data.data.name as string,
        { expires: 30 },
      );
      Cookies.set(
        'accountMobileNumber',
        fetchPersonalProfile.data?.data.data.mobileNumber as string,
        { expires: 30 },
      );
      Cookies.set(
        'accountId',
        fetchPersonalProfile.data?.data.data.id as string,
        { expires: 30 },
      );
      Cookies.set(
        'accountBalance',
        fetchPersonalProfile.data?.data.data.availableBalance?.toString() as string,
        { expires: 30 },
      );
      Cookies.set(
        'accountRate',
        fetchPersonalProfile.data?.data.data.rateNumber?.toString() as string,
        { expires: 30 },
      );

      if (loginRedirectUrl !== BankNoteRoutes.base) {
        window.location.href = loginRedirectUrl as string;
      } else {
        window.location.href = `${BankNoteRoutes.profile}/deals`;
      }
      sessionStorage.removeItem('loginRedirectUrl');
    }
  }, [
    fetchPersonalProfile.data?.data.data.availableBalance,
    fetchPersonalProfile.data?.data.data.id,
    fetchPersonalProfile.data?.data.data.mobileNumber,
    fetchPersonalProfile.data?.data.data.name,
    fetchPersonalProfile.data?.data.data.rateNumber,
    fetchPersonalProfile.data?.data.data.avatar,
    fetchPersonalProfile.isSuccess,
    isUserLoggedIn,
    navigate,
  ]);

  return (
    <Box
      py={8}
      px={1}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{ minWidth: { md: '600px', sm: '100%' } }}
    >
      <img src={LOGO} width={160} alt="logo" />

      <Typography sx={{ my: 3 }} fontSize="16px" color="primary.main">
        ادخل رقم التأكيد الذي وصلك كرسالة
      </Typography>

      <Box sx={{ mt: 3, mb: 5 }}>
        <ReactInputVerificationCode
          autoFocus
          placeholder=""
          onChange={onChange}
          value={code}
          onCompleted={onSubmit}
        />
      </Box>

      {location.pathname !== BankNoteRoutes.base && (
        <Link to={BankNoteRoutes.base}>
          <Typography fontSize="12px" color="primary" sx={{ mt: 3 }}>
            الذهاب إلي الصفحة الرئيسية
          </Typography>
        </Link>
      )}
    </Box>
  );
}
