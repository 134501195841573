import {
  Box,
  Button,
  Container,
  Paper,
  Step,
  StepLabel,
  Stepper,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import React, { MouseEvent, useEffect, useState } from 'react';
import { ArrowForwardIos } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFetchDeal } from 'queries/deals/FetchDeal';
import NEW_1 from 'assests/images/new_1.svg';
import NEW_2 from 'assests/images/new_2.svg';
import NEW_3 from 'assests/images/new_3.svg';
import NEW_1_ACTIVE from 'assests/images/new_1_active.svg';
import NEW_2_ACTIVE from 'assests/images/new_2_active.svg';
import NEW_3_ACTIVE from 'assests/images/new_3_active.svg';
import { UpsertProductDealSteps } from './product';
import { UpsertServiceDealSteps } from './service';

export function UpsertDeal() {
  const [activeStep, setActiveStep] = useState(0);
  const [dealType, setDealType] = useState('product');

  const navigate = useNavigate();
  const location = useLocation();
  const dealId = new URLSearchParams(location.search)?.get('dealId');
  const currentStep = new URLSearchParams(location.search)?.get('step');

  const fetchDeal = useFetchDeal(dealId || '');
  const deal = fetchDeal.data?.data.data;

  const stepperSteps = [
    {
      label: 'الخطوة الأولى',
      description: 'معلومات المنتج أو الخدمة',
      img: NEW_1,
      active: NEW_1_ACTIVE,
    },
    {
      label: 'الخطوة الثانية',
      description: 'معلومات الطرف الآخر',
      img: NEW_2,
      active: NEW_2_ACTIVE,
    },
    {
      label: 'الخطوة الثالثة',
      description: 'ارسال ومشاركة الاتفاقية',
      img: NEW_3,
      active: NEW_3_ACTIVE,
    },
  ];

  function onChangeDealType(
    event: MouseEvent<HTMLElement>,
    newDealType: string,
  ) {
    if (newDealType !== null) {
      setDealType(newDealType);
    }
  }

  function onChangeActiveStep(newActiveStep: number) {
    setActiveStep(newActiveStep);

    if (newActiveStep + 1 !== Number(currentStep)) {
      navigate({
        pathname: location.pathname,
        search: dealId
          ? `dealId=${dealId}&step=${newActiveStep + 1}`
          : `step=${newActiveStep + 1}`,
      });
    }
  }

  useEffect(() => {
    if (currentStep) {
      onChangeActiveStep(Number(Number(currentStep) - 1));
    } else {
      navigate({
        pathname: location.pathname,
        search: 'step=1',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const lastStep = activeStep === 2 ? 'LastStep' : '';
  const secondStep = activeStep === 1 ? 'SecondStep' : '';
  const stepsType = dealType === 'service' ? 'service' : 'product';

  return (
    <Paper
      elevation={3}
      sx={{ my: { xs: '26px', sm: '56px' }, pb: { xs: '26px', sm: '80px' } }}
      className="Deals_wrap"
    >
      <Box
        className="Deals_box"
        width={1}
        justifyContent="center"
        alignItems="center"
        p={2}
        sx={{ backgroundColor: 'primary.main' }}
      >
        <Typography textAlign="center">
          {dealId ? `تعديل الاتفاقية رقم : ${dealId}` : 'انشاء اتفاقية جديدة'}
        </Typography>
      </Box>

      <Container maxWidth="md" className="dealsContent">
        <Box sx={{ width: '100%' }}>
          {activeStep !== 0 && (
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'start',
                mt: 4,
              }}
            >
              <Button
                variant="contained"
                onClick={() => onChangeActiveStep(activeStep - 1)}
              >
                <ArrowForwardIos sx={{ fontSize: '14px' }} />
                <Typography textAlign="center" sx={{ fontSize: '14px', mx: 1 }}>
                  العودة للخطوة السابقة
                </Typography>
              </Button>
            </Box>
          )}
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            sx={{
              mt: { xs: '20px', sm: '30px' },
              mb: { xs: '20px', sm: '30px' },
            }}
            className="StepsBox"
          >
            {stepperSteps.map(({ label, description, img, active }) => (
              <Step key={label}>
                <StepLabel>
                  <img src={img} alt={label} className="dealsSteps normal" />
                  <img src={active} alt={label} className="dealsSteps active" />
                  <Typography className="dealsStepsHead">{label}</Typography>
                  <Typography className="dealsStepsDesc">
                    {description}
                  </Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        {activeStep === 0 && (
          <Box sx={{ width: '100%', mb: '48px' }} className="TapsContainer">
            <ToggleButtonGroup
              color="primary"
              value={dealType}
              exclusive
              onChange={onChangeDealType}
              aria-label="dealType"
              sx={{ width: '100%' }}
              className="taps_box"
            >
              {(!dealId || (deal && deal.type === 'PRODUCT')) && (
                <ToggleButton
                  className="tapbox"
                  value="product"
                  sx={{
                    width: deal && deal.type === 'PRODUCT' ? '100%' : '50%',
                  }}
                >
                  اتفاقية شراء او بيع منتج
                </ToggleButton>
              )}
              {(!dealId || (deal && deal.type === 'SERVICE')) && (
                <ToggleButton
                  className="tapbox"
                  value="service"
                  sx={{
                    width: deal && deal.type === 'SERVICE' ? '100%' : '50%',
                  }}
                >
                  اتفاقية طلب أو تقديم خدمة
                </ToggleButton>
              )}
            </ToggleButtonGroup>
          </Box>
        )}

        <Box
          sx={{ width: '100%' }}
          className={`Taps_content_box newDeal ${stepsType} ${secondStep} ${lastStep}`}
        >
          {dealType === 'product' && (
            <UpsertProductDealSteps
              activeStep={activeStep}
              onChangeActiveStep={onChangeActiveStep}
            />
          )}
          {dealType === 'service' && (
            <UpsertServiceDealSteps
              activeStep={activeStep}
              onChangeActiveStep={onChangeActiveStep}
            />
          )}
        </Box>
      </Container>
    </Paper>
  );
}
