import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { useSnackbar } from 'notistack';
import { IAccount, IBankNoteError } from 'types';

export function useUpdatePersonalProfile() {
  // Get QueryClient from the context
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const {
    error: err,
    data,
    isLoading,
    isSuccess,
    mutate,
    reset,
  } = useMutation({
    mutationKey: ['update-personal-profile'],
    mutationFn: (request: IAccount) => {
      const { id, mobileNumber, ...updateRequest } = request;
      return axios.patch(
        `${import.meta.env.VITE_API_BASE_URL}/users/${id}`,
        updateRequest,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
            'accept-language': 'ar',
          },
        },
      );
    },
    onSuccess: () => {
      enqueueSnackbar('تم تحديث الملف الشخصي بنجاح', {
        variant: 'success',
      });

      queryClient
        .invalidateQueries({
          queryKey: ['fetch-personal-profile'],
        })
        .then(() => {});
    },
    onError: (requestError: AxiosError<IBankNoteError>) => {
      enqueueSnackbar(
        requestError?.response?.data?.errors[0].message ||
          requestError?.response?.data?.message ||
          '',
        {
          variant: 'error',
        },
      );
    },
  });

  const error = err as AxiosError;

  return {
    error,
    data,
    isLoading,
    isSuccess,
    mutate,
    reset,
  };
}
