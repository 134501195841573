import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  styled,
  Typography,
} from '@mui/material';
import { Badge } from '@mui/icons-material';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import MADA_PAY from 'assests/images/mada-pay.svg';
import MASTER_CARD from 'assests/images/master-card.svg';
import STC_PAY from 'assests/images/stc-pay.svg';
import APPLE_PAY from 'assests/images/apple-pay.svg';
import { usePayDeal } from 'queries/deals/PayDeal';
import { useFetchDeal } from 'queries/deals/FetchDeal';
import { useFetchDealApplePayLink } from 'queries/deals/FetchApplePayLink';
import { useFetchDealOnlinePayLink } from 'queries/deals/FetchOnlinePayLink';
import { IDeal, PaymentMethods as PaymentMethodsTypes } from 'types';

const DisabledOverlay = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 2;
  cursor: none;
  pointer-events: none;
`;

const PaymentCard = styled(Card)`
  border-radius: 4px;
  min-height: 120px;
`;

const PaymentCardContent = styled(CardContent)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 8px;
  height: 100%;
`;

export function PaymentMethods() {
  const { id: prettyId } = useParams();
  const navigate = useNavigate();

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodsTypes>(
    PaymentMethodsTypes.onlinePayment,
  );

  const fetchDeal = useFetchDeal(prettyId);
  const payDeal = usePayDeal();

  const deal = fetchDeal.data?.data.data as IDeal;
  const dealId = deal?.id;
  const itemPrice = Number(deal?.itemPrice);
  const totalToBePaid = itemPrice + Number(deal?.commission);

  const fetchDealApplePayLink = useFetchDealApplePayLink(dealId);
  const fetchDealOnlinePayLink = useFetchDealOnlinePayLink(dealId);

  function onPayBankTransfer() {
    payDeal.mutate(dealId, {
      onSuccess: () => {
        navigate(`/profile/deals/${prettyId}`);
      },
    });
  }

  function onSelectPaymentMethod(selectedPaymentMethod: PaymentMethodsTypes) {
    setPaymentMethod(selectedPaymentMethod);
  }

  function onPay() {
    if (paymentMethod === PaymentMethodsTypes.bankTransfer) {
      onPayBankTransfer();
    } else if (paymentMethod === PaymentMethodsTypes.onlinePayment) {
      window.location.href = fetchDealOnlinePayLink.data?.data.transaction.url;
    } else if (paymentMethod === PaymentMethodsTypes.applePay) {
      window.location.href = fetchDealApplePayLink.data?.data.transaction.url;
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        px: { xs: 4, sm: 4 },
        py: { xs: '26px', sm: 8 },
      }}
    >
      <Grid display="grid" gridTemplateColumns="max-content auto" gap={2}>
        <Box sx={{ display: 'flex', alignItems: 'end', gap: 1 }}>
          <Badge color="primary" sx={{ fontSize: 24 }} />
          <Typography color="primary" fontSize="18px">
            طريقة الدفع
          </Typography>
        </Box>
        <Divider />
      </Grid>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <Grid
          sx={{
            display: 'grid',
            width: '100%',
            gridTemplateColumns: 'repeat(auto-fill, minmax(160px, 1fr))',
            gridGap: '8px',
            alignItems: 'stretch',
            mt: 3,
          }}
        >
          <PaymentCard
            sx={{
              border: `1px solid ${
                paymentMethod === PaymentMethodsTypes.onlinePayment
                  ? '#0558A9'
                  : 'rgba(0, 0, 0, 0.26)'
              }`,
              cursor: 'pointer',
            }}
            onClick={() =>
              onSelectPaymentMethod(PaymentMethodsTypes.onlinePayment)
            }
          >
            <PaymentCardContent>
              <img
                src={MADA_PAY}
                alt="mada-pay"
                width="40%"
                style={{ marginBottom: '10px' }}
              />
              <img src={MASTER_CARD} alt="master-card" width="40%" />
            </PaymentCardContent>
          </PaymentCard>

          <PaymentCard
            // sx={{ border: '1px solid rgba(0, 0, 0, 0.26)' }}
            sx={{
              border: `1px solid ${
                paymentMethod === PaymentMethodsTypes.applePay
                  ? '#0558A9'
                  : 'rgba(0, 0, 0, 0.26)'
              }`,
              cursor: 'pointer',
            }}
            onClick={() => onSelectPaymentMethod(PaymentMethodsTypes.applePay)}
          >
            <PaymentCardContent>
              <img src={APPLE_PAY} alt="apple-pay" width="50%" />
            </PaymentCardContent>
          </PaymentCard>

          <PaymentCard sx={{ border: '1px solid rgba(0, 0, 0, 0.26)' }}>
            <PaymentCardContent>
              <DisabledOverlay />
              <img src={STC_PAY} alt="stc-pay" width="50%" />
            </PaymentCardContent>
          </PaymentCard>
        </Grid>

        <Typography fontSize="14px" color="primary" mt={3} mb={1}>
          الاجمالي : {deal?.itemPrice} + {Number(deal?.commission)} (عمولة) ={' '}
          {Number(totalToBePaid).toFixed(3)} ريال
        </Typography>
        {paymentMethod === PaymentMethodsTypes.bankTransfer && (
          <>
            <Typography fontSize="12px" fontWeight="bold" mt={3}>
              اسم المستقبل: مؤسسة بنكنوت
            </Typography>
            <Typography fontSize="12px" fontWeight="bold" my={1}>
              حساب المستقبل: SA7010000001400015258000
            </Typography>
          </>
        )}
        <LoadingButton
          variant="contained"
          loadingPosition="start"
          onClick={onPay}
          disabled={
            fetchDealApplePayLink.isLoading || fetchDealOnlinePayLink.isLoading
          }
        >
          الدفع
        </LoadingButton>
      </Box>
    </Box>
  );
}
