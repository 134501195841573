import { Controller, useForm } from 'react-hook-form';
import {
  Grid,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Box,
  Button,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { FilesUploader } from 'components';
import { base64toFile, convertImageUrlToBase64 } from 'utilities/methods';
import { useFetchDeal } from 'queries/deals/FetchDeal';
import { IUpsertDeal } from '../../_common';

type IItemImages = {
  name: string;
  content: string;
};

type IUpsertServiceDealForm = {
  accountRole: string;
  itemName: string;
  itemType: string;
  itemPrice: string;
  deliveryDate: string;
  itemImagesUrl: IItemImages[];
  itemDescription: string;
};

export function ServiceInfoStep({ onChangeActiveStep }: IUpsertDeal) {
  const [itemImagesUrl, setItemImagesUrl] = useState<IItemImages[]>([]);
  const [uploaderFiles, setUploaderFiles] = useState<File[]>([]);

  const search = useLocation().search;
  const dealId = new URLSearchParams(search)?.get('dealId');
  const fetchDeal = useFetchDeal(dealId || '');

  const accountId = Cookies.get('accountId');

  function onUploadImages(filesUploaded: File[]) {
    const reader = new FileReader();

    filesUploaded.forEach((file) => {
      reader.readAsDataURL(file);

      reader.onload = () => {
        setItemImagesUrl([
          ...itemImagesUrl,
          { name: file.name, content: reader.result as string },
        ]);
      };
    });
  }

  const accountRoleSelectOptions = [
    {
      label: 'أنا مقدم الخدمة',
      value: 'SELLER',
    },
    {
      label: 'أنا مستفيد من الخدمة',
      value: 'BUYER',
    },
  ];

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
  } = useForm<IUpsertServiceDealForm>();

  function onSubmit(data: IUpsertServiceDealForm) {
    const computedData = {
      ...data,
      itemImagesUrl,
      type: 'SERVICE',
    };

    localStorage.setItem('upsert-deal-step-1', JSON.stringify(computedData));
    onChangeActiveStep(1);
  }

  useEffect(() => {
    const step1Info = localStorage.getItem('upsert-deal-step-1');
    const parsedStep1Info = JSON.parse(step1Info as string);

    if (step1Info) {
      parsedStep1Info.itemImagesUrl?.forEach(async (itemImage: IItemImages) => {
        const file = await base64toFile(itemImage.content, itemImage.name);

        setUploaderFiles([...uploaderFiles, file]);
      });

      reset({
        itemName: parsedStep1Info.itemName,
        itemType: parsedStep1Info.itemType,
        deliveryDate: parsedStep1Info.deliveryDate,
        accountRole: parsedStep1Info.accountRole,
        itemDescription: parsedStep1Info.itemDescription,
        itemPrice: parsedStep1Info.itemPrice,
        itemImagesUrl: parsedStep1Info.itemImagesUrl,
      });

      setItemImagesUrl(parsedStep1Info.itemImagesUrl);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const step1Info = localStorage.getItem('upsert-deal-step-1');
    const deal = fetchDeal.data?.data.data;

    if (deal && !step1Info) {
      const itemImagesPromise = new Promise<void>((resolve) => {
        if (deal.itemImagesUrl.length > 0) {
          deal.itemImagesUrl.forEach(async (imageUrl, index) => {
            const imageBase64 = await convertImageUrlToBase64(imageUrl);
            const fileName = imageUrl.split('?')[0];
            const file = await base64toFile(imageBase64, fileName);

            setUploaderFiles([...uploaderFiles, file]);
            setItemImagesUrl([
              ...itemImagesUrl,
              {
                name: fileName,
                content: imageBase64,
              },
            ]);

            if (index === deal.itemImagesUrl.length - 1) {
              resolve();
            }
          });
        }
        resolve();
      });

      itemImagesPromise.then(() => {
        reset({
          itemName: deal.itemName,
          itemType: deal.itemType,
          deliveryDate: deal.deliveryDate,
          accountRole: deal.buyer.id === accountId ? 'BUYER' : 'SELLER',
          itemDescription: deal.itemDescription || undefined,
          itemPrice: deal.itemPrice.toString(),
          itemImagesUrl,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDeal.data]);

  let deliveryDateErrorText = ' ';

  if (errors.deliveryDate?.type === 'required') {
    deliveryDateErrorText = errors.deliveryDate?.message as string;
  } else if (errors.deliveryDate?.type === 'validate') {
    deliveryDateErrorText = errors.deliveryDate?.message as string;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid width={1} display="grid" gap={2} gridTemplateColumns="1fr">
        <Controller
          name="accountRole"
          control={control}
          rules={{ required: true }}
          defaultValue=""
          render={({ field }) => (
            <FormControl>
              <InputLabel
                id="upsert-product-deal-account-role-select-label"
                variant="filled"
                shrink
                error={!!errors.accountRole}
              >
                أنت في هذه الإتفاقية كــــ
              </InputLabel>
              <Select
                {...field}
                displayEmpty
                variant="filled"
                labelId="upsert-product-deal-account-role-select-label"
                id="upsert-product-deal-account-role-select"
                label="أنت في هذه الإتفاقية كــــ"
                error={!!errors.accountRole}
                onChange={field.onChange}
                renderValue={() => {
                  if (!getValues().accountRole) {
                    return (
                      <Typography fontSize="14px" color="#BCBCBC">
                        اختر مقدم خدمة او مستفيد
                      </Typography>
                    );
                  }

                  return accountRoleSelectOptions.find(
                    (option) => getValues().accountRole === option.value,
                  )?.label;
                }}
              >
                {accountRoleSelectOptions.map((option) => (
                  <MenuItem
                    key={option.label}
                    value={option.value}
                    sx={{ fontSize: '14px' }}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error={!!errors.accountRole}>
                {errors.accountRole ? 'هذا الحقل مطلوب' : ' '}
              </FormHelperText>
            </FormControl>
          )}
        />

        <Controller
          name="itemName"
          control={control}
          rules={{ required: true }}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              variant="filled"
              label="اسم الخدمة ( حقل مطلوب )"
              InputLabelProps={{ shrink: true }}
              placeholder="من فضلك, اكتب اسم او نوع الخدمة"
              error={!!errors.itemName}
              helperText={errors.itemName ? 'هذا الحقل مطلوب' : ' '}
            />
          )}
        />

        <Controller
          name="itemPrice"
          control={control}
          rules={{ required: true }}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              variant="filled"
              label="سعر الخدمة ( حقل مطلوب )"
              InputLabelProps={{ shrink: true }}
              placeholder="من فضلك, ضع سعر الخدمة بالريال السعودي"
              error={!!errors.itemPrice}
              helperText={errors.itemPrice ? 'هذا الحقل مطلوب' : ' '}
            />
          )}
        />

        <Controller
          name="deliveryDate"
          control={control}
          rules={{
            required: { value: true, message: 'هذا الحقل مطلوب' },
            validate: (dateValue) => {
              const deliveryDate = new Date(dateValue);
              const nowDate = new Date();

              const deliveryDateWithoutTime = new Date(deliveryDate.getTime());
              const nowDateWithoutTime = new Date(nowDate.getTime());

              deliveryDateWithoutTime.setHours(0, 0, 0, 0);
              nowDateWithoutTime.setHours(0, 0, 0, 0);

              return (
                deliveryDateWithoutTime >= nowDateWithoutTime ||
                'لا يجب اختيار تاريخ فبل تاريخ اليوم'
              );
            },
          }}
          defaultValue=""
          render={({ field }) => (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                {...field}
                inputFormat="DD/MM/YYYY"
                disablePast
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label="المدة المتفق عليها ( حقل مطلوب )"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      ...params.inputProps,
                      placeholder:
                        'المدة المتفق عليها للحصول على الخدمة او الانتهاء من تقديم الخدمة (dd/mm/yyyy)',
                    }}
                    error={!!errors.deliveryDate}
                    helperText={deliveryDateErrorText}
                  />
                )}
              />
            </LocalizationProvider>
          )}
        />

        <Controller
          name="itemDescription"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              multiline
              className="DealsTextAreaFeild"
              minRows={7}
              variant="filled"
              label="وصف أكثر للخدمة"
              inputProps={{ style: { fontSize: '14px' } }}
              InputLabelProps={{ shrink: true }}
              placeholder="لضمان وصول نفس المنتج المطلوب, الرجاء ذكر تفاصيل المنتج هنا وأي شروط او ملحقات أو تفاصيل تخص هذه الاتفاقية لضمان الحصول على الخدمة بشكل مثالي, الرجاء ذكر تفاصيل الخدمة وأي شروط او ملحقات أو تفاصيل تخص هذه الخدمة"
              helperText=" "
            />
          )}
        />

        <Box width={1} sx={{ mb: '16px' }}>
          <FilesUploader onChange={onUploadImages} files={uploaderFiles} />
        </Box>
      </Grid>

      <Button
        sx={{ width: '100%', mt: '32px' }}
        variant="contained"
        type="submit"
      >
        الانتقال للخطوة الثانية
      </Button>
    </form>
  );
}
