import { createTheme } from '@mui/material';
import '@mui/material/styles';

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    white?: Palette['primary'];
  }
  interface PaletteOptions {
    white?: PaletteOptions['primary'];
  }
}
export const theme = createTheme({
  palette: {
    primary: {
      main: '#0558A9',
    },
    secondary: {
      main: '#F38D1E',
    },
    error: {
      main: '#D32F2F',
    },
    white: {
      main: '#FFFFFF',
    },
  },
  direction: 'rtl',
  typography: {
    fontFamily: "'Noto Sans Arabic', sans-serif",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @keyframes spin {
          0% { transform: rotate(360deg); }
          100% { transform: rotate(0deg); }
        }
        
        body {
          --ReactInputVerificationCode-itemWidth: 80px;
          --ReactInputVerificationCode-itemHeight: 40px;
        }
        
        body .ReactInputVerificationCode__item {
          position: relative;
          color: #262626;
          font-weight: 500;
        }
        
        body .ReactInputVerificationCode__item,
        body .ReactInputVerificationCode__item.is-active {
          box-shadow: none;
        }
        
        body .ReactInputVerificationCode__item:after {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          bottom: 0;
          right: 0;
          height: 2px;
          background-color: #ebebeb;
          transition: background-color 0.2s ease-out;
        }
        
        body .ReactInputVerificationCode__item.is-active:after {
          background-color: #0558A9;
        }
        
        body .SnackbarItem-contentRoot {
          font-family: Noto Sans Arabic, sans-serif !important;
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '14px',
        },
        startIcon: ({ theme: bankNoteTheme }) => ({
          marginRight: bankNoteTheme.direction === 'rtl' ? '-4px' : '8px',
          marginLeft: bankNoteTheme.direction === 'rtl' ? '8px' : '-4px',
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          input: {
            fontSize: '14px',
            '::placeholder': {
              color: '#858585',
            },
          },
          textarea: {
            '::placeholder': {
              color: '#858585',
            },
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: '#BCBCBC',
          fontSize: '10px',
          textAlign: 'right',
          margin: 0,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        filled: ({ theme: bankNoteTheme }) => ({
          transform:
            bankNoteTheme.direction === 'rtl'
              ? 'translate(-12px, 7px) scale(0.75)'
              : 'translate(12px, 7px) scale(0.75)',
        }),
        shrink: ({ theme: bankNoteTheme }) => ({
          right: bankNoteTheme.direction === 'rtl' ? 0 : 'initial',
          transformOrigin:
            bankNoteTheme.direction === 'rtl' ? 'top right' : 'top left',
        }),
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        alternativeLabel: ({ theme: bankNoteTheme }) => ({
          right:
            bankNoteTheme.direction === 'rtl'
              ? 'calc(-50% + 20px)'
              : 'calc(50% + 20px)',
          left:
            bankNoteTheme.direction === 'rtl'
              ? 'calc(50% + 20px)'
              : 'calc(-50% + 20px)',
        }),
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        grouped: {
          ':not(:first-of-type)': {
            marginLeft: 0,
            borderLeftColor: 'rgba(0, 0, 0, 0.12)',
            borderTopLeftRadius: 'inherit',
            borderBottomLeftRadius: 'inherit',
            marginRight: '-1px',
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          },
          ':not(:last-of-type)': {
            marginRight: 0,
            borderRightColor: 'rgba(0, 0, 0, 0.12)',
            borderTopRightRadius: 'inherit',
            borderBottomRightRadius: 'inherit',
            marginLeft: '-1px',
            borderLeft: '1px solid transparent',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: '14px',
        },
        filled: ({ theme: bankNoteTheme }) => ({
          paddingLeft: bankNoteTheme.direction === 'rtl' ? '32px' : '12px',
          paddingRight:
            bankNoteTheme.direction === 'rtl' ? '12px !important' : '32px',
        }),
        iconFilled: ({ theme: bankNoteTheme }) => ({
          right: bankNoteTheme.direction === 'rtl' ? 'initial' : '7px',
          left: bankNoteTheme.direction === 'rtl' ? '7px' : 'initial',
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme: bankNoteTheme }) => ({
          '& .MuiTab-iconWrapper': {
            marginLeft: bankNoteTheme.direction === 'rtl' ? '8px' : 0,
            marginRight: bankNoteTheme.direction === 'rtl' ? 0 : '8px',
          },
        }),
      },
    },
    MuiRating: {
      styleOverrides: {
        readOnly: ({ theme: bankNoteTheme }) => ({
          '& .MuiRating-decimal span:first-child': {
            right: bankNoteTheme.direction === 'rtl' ? 0 : 'initial',
          },
        }),
        label: ({ theme: bankNoteTheme }) => ({
          right: bankNoteTheme.direction === 'rtl' ? 0 : 'initial',
        }),
      },
    },
  },
});
