import { Box, Button, Grid, Rating, Typography } from '@mui/material';
import { PanoramaFishEye } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getDownloadURL, ref, uploadString } from 'firebase/storage';
import LoadingButton from '@mui/lab/LoadingButton';
import Cookies from 'js-cookie';
import { CopyClipboardInput } from 'components';
import OK_HAND from 'assests/images/deals_2.svg';
import STEP3_1 from 'assests/images/step3_1.svg';
import STEP3_2 from 'assests/images/step3_2.svg';
import STEP3_3 from 'assests/images/step3_3.svg';
import { useCreateDeal } from 'queries/deals/CreateDeal';
import { IAccount, IDeal } from 'types';
import { BankNoteRoutes } from 'BankNoteRoutes';
import { useFetchDeal } from 'queries/deals/FetchDeal';
import { useEditDeal } from 'queries/deals/editDeal';
import { storage } from 'App';
import { convertArabicNumbersToEnglish } from 'utilities';
import { useFetchUserProfileByMobileNumber } from 'queries/users/FetchUserProfileByMobileNumber';
import { IOppositeAccountStepForm } from '../oppositeAccountStep';

export function DealSummaryStep() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const search = useLocation().search;
  const dealId = new URLSearchParams(search).get('dealId');
  const navigate = useNavigate();

  const accountId = Cookies.get('accountId');

  const fetchDeal = useFetchDeal(dealId || '');
  const deal = fetchDeal.data?.data.data;

  const createDeal = useCreateDeal();
  const editDeal = useEditDeal();

  let createDealData = {} as Omit<
    IDeal,
    'buyer' | 'seller' | 'accountRole' | 'itemImagesUrl'
  > & {
    oppositeAccount: IAccount;
    accountRole: 'SELLER' | 'BUYER';
    itemPrice: string;
    itemImagesUrl: { name: string; content: string }[];
  };

  const step1Info = localStorage.getItem('upsert-deal-step-1');
  const step2Info = localStorage.getItem('upsert-deal-step-2');

  if (step1Info) {
    const parsedStep1Info = JSON.parse(step1Info) as Omit<
      IDeal,
      'buyer' | 'seller' | 'accountRole' | 'itemImagesUrl'
    > & {
      oppositeAccount: IAccount;
      accountRole: 'SELLER' | 'BUYER';
      itemPrice: string;
      itemImagesUrl: { name: string; content: string }[];
    };

    createDealData = {
      ...parsedStep1Info,
      deliveryDate: new Date(parsedStep1Info.deliveryDate).toLocaleDateString(
        'en-US',
      ),
    };
  }

  if (step2Info) {
    const parsedStep2Info = JSON.parse(step2Info) as IOppositeAccountStepForm;

    createDealData = {
      ...createDealData,
      oppositeAccount: parsedStep2Info,
    };
  }

  const fetchUserProfileByMobileNumber = useFetchUserProfileByMobileNumber(
    '+966'.concat(
      convertArabicNumbersToEnglish(
        createDealData.oppositeAccount?.mobileNumber || '',
      ),
    ) || '',
  );

  function onGoToDealsPage() {
    navigate(`${BankNoteRoutes.profile}/deals`);
  }

  useEffect(() => {
    if (!createDeal.isLoading && !editDeal.isLoading) {
      setIsSubmitting(false);
    }
  }, [createDeal.isLoading, editDeal.isLoading]);

  let oppositeAccountName = 'لايوجد';

  if (fetchUserProfileByMobileNumber.data?.data.data.name) {
    oppositeAccountName = fetchUserProfileByMobileNumber.data?.data.data.name;
  } else if (createDealData.oppositeAccount?.name) {
    oppositeAccountName = createDealData.oppositeAccount.name;
  }

  let oppositeAccountEmail = 'لايوجد';

  if (fetchUserProfileByMobileNumber.data?.data.data.email) {
    oppositeAccountEmail = fetchUserProfileByMobileNumber.data?.data.data.email;
  } else if (createDealData.oppositeAccount?.email) {
    oppositeAccountEmail = createDealData.oppositeAccount.email;
  }

  function onSubmit() {
    setIsSubmitting(true);

    const itemImagesUrl: string[] = [];
    const promises: any[] = [];

    if (createDealData.itemImagesUrl.length > 0) {
      createDealData.itemImagesUrl.forEach(({ name, content }) => {
        const base64string = content.split(',').pop() as string;

        const storageRef = ref(
          storage,
          `/deals-images/${createDealData.type}-${createDealData.itemName}-${name}`,
        );

        promises.push(
          uploadString(storageRef, base64string, 'base64').then(async () => {
            await getDownloadURL(storageRef).then((url) => {
              itemImagesUrl.push(url);
            });
          }),
        );
      });
    }

    Promise.all(promises).then(() => {
      const oppositeAccount = {
        ...createDealData.oppositeAccount,
        name: oppositeAccountName !== 'لايوجد' ? oppositeAccountName : '',
        email: oppositeAccountEmail !== 'لايوجد' ? oppositeAccountEmail : null,
        mobileNumber: '+966'.concat(
          convertArabicNumbersToEnglish(
            createDealData.oppositeAccount.mobileNumber,
          ),
        ),
      };

      const buyer =
        deal?.buyer.id === accountId ? deal?.buyer : oppositeAccount;
      const seller =
        deal?.seller.id === accountId ? deal?.seller : oppositeAccount;

      if (dealId) {
        editDeal.mutate({
          ...deal,
          accountRole: createDealData.accountRole,
          type: createDealData.type,
          itemName: createDealData.itemName,
          itemType: createDealData.type,
          itemPrice: Number(
            convertArabicNumbersToEnglish(createDealData.itemPrice),
          ),
          deliveryDate: new Date(
            createDealData.deliveryDate,
          ).toLocaleDateString('en-US'),
          externalItemLink: createDealData.externalItemLink || null,
          itemImagesUrl: itemImagesUrl || null,
          itemDescription: createDealData.itemDescription || null,
          buyer,
          seller,
        });
      } else {
        createDeal.mutate({
          accountRole: createDealData.accountRole,
          type: createDealData.type,
          itemName: createDealData.itemName,
          itemType: createDealData.type,
          itemPrice: Number(
            convertArabicNumbersToEnglish(createDealData.itemPrice),
          ),
          deliveryDate: new Date(
            createDealData.deliveryDate,
          ).toLocaleDateString('en-US'),
          externalItemLink: createDealData.externalItemLink || null,
          itemImagesUrl: itemImagesUrl || null,
          itemDescription: createDealData.itemDescription || null,
          oppositeAccount: {
            ...createDealData.oppositeAccount,
            email: createDealData.oppositeAccount.email || null,
            mobileNumber: '+966'.concat(
              convertArabicNumbersToEnglish(
                createDealData.oppositeAccount.mobileNumber,
              ),
            ),
          },
        });
      }
    });
  }

  if (createDeal.isSuccess || editDeal.isSuccess) {
    localStorage.removeItem('upsert-deal-step-1');
    localStorage.removeItem('upsert-deal-step-2');

    return (
      <Box
        width={1}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box sx={{ width: '220px', mb: 1 }}>
          <img src={OK_HAND} width="100%" alt="confirm-deal" />
        </Box>
        <Typography fontSize="14px" sx={{ mb: 3 }}>
          تم ارسال رسالة نصية للطرف الآخر, ويمكنك مشاركة رابط الاتفاقية عبر نسخ
          الرابط التالي
        </Typography>
        <Box width={0.8}>
          <CopyClipboardInput
            content={`${window.location.origin}/profile/deals/${
              createDeal.data?.data.data.prettyId ||
              editDeal.data?.data.data.prettyId
            }`}
            isLoading={false}
          />
        </Box>

        <Button
          sx={{ width: '100%', mt: '60px' }}
          variant="contained"
          onClick={onGoToDealsPage}
        >
          الذهاب الي الاتفاقيات
        </Button>
      </Box>
    );
  }

  return (
    <Grid display="flex" flexDirection="column" className="DealLastStep">
      <Box className="LastStepHead">
        <Typography color="primary" fontSize="24px">
          مراجعة نهائية للاتفاقية
        </Typography>
      </Box>

      <Grid
        display="grid"
        gridTemplateColumns="max-content max-content max-content"
        gap={1}
        sx={{ mt: 2, mb: 3 }}
        className="lastStepCreatedDate"
      >
        <img src={STEP3_1} width="100%" alt="date" />
        <Typography fontSize="14px" color="primary" fontWeight="bold">
          تاريخ الاتفاقية:
        </Typography>
        <Typography fontSize="14px" color="primary" fontWeight="lighter">
          {new Date().toLocaleDateString('ar-EG')}
        </Typography>
      </Grid>

      <Grid
        display="grid"
        gridTemplateColumns="64% 34%"
        sx={{
          border: 0,
          borderRight: 0,
          borderLeft: 0,
          gridTemplateColumns: { md: '50% 50%', sm: '1fr' },
          borderBottom: {
            md: 0,
            sm: 0,
            xs: 0,
          },
        }}
        className="lastDealWrap"
      >
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            borderLeft: {
              md: 0,
              sm: 0,
              xs: 0,
            },
            borderBottom: {
              md: 0,
              sm: 0,
              xs: 0,
            },
            py: 3,
            pl: 2,
          }}
        >
          <Typography mb={2} className="lastDealSubHead">
            معلومات المنتج أو الخدمة
          </Typography>
          <Grid display="grid" gap={1} className="lastStepList">
            <Grid
              display="grid"
              gridTemplateColumns="max-content auto"
              gap={1}
              alignItems="start"
            >
              <PanoramaFishEye
                sx={{ color: '#8D8D8D', fontSize: '14px', mt: '2px' }}
              />
              <Grid
                display="grid"
                gap={2}
                gridTemplateColumns="max-content auto"
              >
                <Typography fontSize="14px" color="#8D8D8D">
                  أنت في هذه الاتفاقية:
                </Typography>
                <Typography fontSize="14px" color="#8D8D8D">
                  {createDealData.accountRole === 'SELLER' ? 'بائع' : 'مشتري'}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              display="grid"
              gridTemplateColumns="max-content auto"
              gap={1}
              alignItems="start"
            >
              <PanoramaFishEye
                sx={{ color: '#8D8D8D', fontSize: '14px', mt: '2px' }}
              />
              <Grid
                display="grid"
                gap={2}
                gridTemplateColumns="max-content auto"
              >
                <Typography fontSize="14px" color="#8D8D8D">
                  اسم المنتج:
                </Typography>
                <Typography fontSize="14px" color="#8D8D8D">
                  {createDealData.itemName}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              display="grid"
              gridTemplateColumns="max-content auto"
              gap={1}
              alignItems="start"
            >
              <PanoramaFishEye
                sx={{ color: '#8D8D8D', fontSize: '14px', mt: '2px' }}
              />
              <Grid
                display="grid"
                gap={2}
                gridTemplateColumns="max-content auto"
              >
                <Typography fontSize="14px" color="#8D8D8D">
                  نوع المنتج او الخدمة:
                </Typography>
                <Typography fontSize="14px" color="#8D8D8D">
                  {createDealData.itemType || 'عام'}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              display="grid"
              gridTemplateColumns="max-content auto"
              gap={1}
              alignItems="start"
            >
              <PanoramaFishEye
                sx={{ color: '#8D8D8D', fontSize: '14px', mt: '2px' }}
              />
              <Grid
                display="grid"
                gap={2}
                gridTemplateColumns="max-content auto"
              >
                <Typography fontSize="14px" color="#8D8D8D">
                  السعر او التكلفة:
                </Typography>
                <Typography fontSize="14px" color="#8D8D8D">
                  {createDealData.itemPrice} ريال
                </Typography>
              </Grid>
            </Grid>

            <Grid
              display="grid"
              gridTemplateColumns="max-content auto"
              gap={1}
              alignItems="start"
            >
              <PanoramaFishEye
                sx={{ color: '#8D8D8D', fontSize: '14px', mt: '2px' }}
              />
              <Grid
                display="grid"
                gap={2}
                gridTemplateColumns="max-content auto"
              >
                <Typography fontSize="14px" color="#8D8D8D">
                  تاريخ توصيل المنتج:
                </Typography>
                <Typography fontSize="14px" color="#8D8D8D">
                  {new Date(createDealData.deliveryDate).toLocaleDateString(
                    'ar-EG',
                  )}
                </Typography>
              </Grid>
            </Grid>

            {createDealData.externalItemLink && (
              <Grid
                display="grid"
                gap={1}
                alignItems="start"
                gridTemplateColumns="max-content auto"
              >
                <PanoramaFishEye
                  sx={{ color: '#8D8D8D', fontSize: '14px', mt: '2px' }}
                />
                <Grid
                  display="grid"
                  gap={2}
                  sx={{
                    gridTemplateColumns: {
                      sm: 'max-content auto',
                      xs: 'max-content 200px',
                    },
                  }}
                >
                  <Typography fontSize="14px" color="#8D8D8D">
                    رابط صفحة المنتج:
                  </Typography>
                  <Typography
                    fontSize="14px"
                    color="#8D8D8D"
                    sx={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {createDealData.externalItemLink}
                  </Typography>
                </Grid>
              </Grid>
            )}

            {createDealData.itemDescription && (
              <Grid
                display="grid"
                gridTemplateColumns="max-content auto"
                gap={1}
                alignItems="start"
              >
                <PanoramaFishEye
                  sx={{ color: '#8D8D8D', fontSize: '14px', mt: '2px' }}
                />
                <Grid
                  display="grid"
                  gap={2}
                  sx={{
                    gridTemplateColumns: {
                      sm: 'max-content auto',
                      xs: 'max-content 200px',
                    },
                  }}
                >
                  <Typography fontSize="14px" color="#8D8D8D">
                    شروط ومواصفات أخرى:
                  </Typography>
                  <Typography
                    fontSize="14px"
                    color="#8D8D8D"
                    sx={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {createDealData.itemDescription}
                  </Typography>
                </Grid>
              </Grid>
            )}

            {createDealData.itemImagesUrl &&
              createDealData.itemImagesUrl.length > 0 && (
                <Grid
                  display="grid"
                  gridTemplateColumns="max-content auto"
                  gap={1}
                  alignItems="start"
                >
                  <PanoramaFishEye
                    sx={{ color: '#8D8D8D', fontSize: '14px', mt: '2px' }}
                  />
                  <Grid display="grid" gap={1}>
                    <Typography fontSize="14px" color="#8D8D8D">
                      صور المنتج:
                    </Typography>
                    <Grid
                      display="grid"
                      width={1}
                      gap={1}
                      sx={{
                        gridTemplateColumns: {
                          sm: 'repeat(auto-fill, 80px)',
                          xs: 'repeat(3, 80px)',
                        },
                      }}
                    >
                      {createDealData.itemImagesUrl.map((image) => (
                        <img
                          src={image.content}
                          width="80px"
                          height="80px"
                          alt={image.name}
                          style={{
                            border: '1px solid rgba(0, 0, 0, 0.07)',
                            borderRadius: '4px',
                            objectFit: 'contain',
                          }}
                        />
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            <img src={STEP3_2} width="100%" alt="" />
          </Grid>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          sx={{
            py: 3,
            pr: 2,
          }}
        >
          <Typography mb={2} className="lastDealSubHead">
            معلومات الطرف الآخر
          </Typography>
          <Grid display="grid" gap={1} className="lastStepList">
            <Grid
              display="grid"
              gridTemplateColumns="max-content auto"
              gap={1}
              alignItems="start"
            >
              <PanoramaFishEye
                sx={{ color: '#8D8D8D', fontSize: '14px', mt: '2px' }}
              />
              <Grid
                display="grid"
                gap={2}
                gridTemplateColumns="max-content auto"
              >
                <Typography fontSize="14px" color="#8D8D8D">
                  الاسم:
                </Typography>
                <Typography fontSize="14px" color="#8D8D8D">
                  {oppositeAccountName}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              display="grid"
              gridTemplateColumns="max-content auto"
              gap={1}
              alignItems="start"
            >
              <PanoramaFishEye
                sx={{ color: '#8D8D8D', fontSize: '14px', mt: '2px' }}
              />
              <Grid
                display="grid"
                gap={2}
                gridTemplateColumns="max-content auto"
              >
                <Typography fontSize="14px" color="#8D8D8D">
                  رقم الجوال:
                </Typography>
                <Typography fontSize="14px" color="#8D8D8D">
                  {'+966'.concat(
                    convertArabicNumbersToEnglish(
                      createDealData.oppositeAccount?.mobileNumber || '',
                    ),
                  )}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              display="grid"
              gridTemplateColumns="max-content auto"
              gap={1}
              alignItems="start"
            >
              <PanoramaFishEye
                sx={{ color: '#8D8D8D', fontSize: '14px', mt: '2px' }}
              />
              <Grid
                display="grid"
                gap={2}
                gridTemplateColumns="max-content auto"
              >
                <Typography fontSize="14px" color="#8D8D8D">
                  البريد الالكتروني:
                </Typography>
                <Typography fontSize="14px" color="#8D8D8D">
                  {oppositeAccountEmail}
                </Typography>
              </Grid>
            </Grid>

            {createDealData.oppositeAccount?.rate && (
              <Grid
                display="grid"
                gridTemplateColumns="max-content auto"
                gap={1}
                alignItems="start"
              >
                <PanoramaFishEye
                  sx={{ color: '#8D8D8D', fontSize: '14px', mt: '2px' }}
                />
                <Grid
                  display="grid"
                  gap={2}
                  gridTemplateColumns="max-content auto"
                >
                  <Typography fontSize="14px" color="#8D8D8D">
                    التقييم:
                  </Typography>
                  <Typography fontSize="14px" color="#8D8D8D">
                    <Rating name="read-only" value={2} size="small" readOnly />
                  </Typography>
                </Grid>
              </Grid>
            )}
            <img src={STEP3_3} width="100%" alt="" />
          </Grid>
        </Box>
      </Grid>

      <LoadingButton
        sx={{ width: '100%', mt: '32px' }}
        variant="contained"
        onClick={onSubmit}
        loadingPosition="start"
        loading={isSubmitting}
      >
        {dealId ? 'تعديل' : 'تأكيد'} الإتفاقية وارسال نسخة للطرف الآخر
      </LoadingButton>
    </Grid>
  );
}
