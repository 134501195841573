import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { DealStatusesColors, IDeal } from 'types';
import PLACEHOLDER_IMAGE from 'assests/images/placeholder-image.svg';

type IDealCard = {
  deal: IDeal;
};

export function DealCard({ deal }: IDealCard) {
  const navigate = useNavigate();

  const accountId = Cookies.get('accountId');

  return (
    <Card sx={{ maxWidth: 375 }}>
      <CardActionArea
        onClick={() => navigate(`/profile/deals/${deal.prettyId}`)}
      >
        <CardMedia
          component="img"
          height="140"
          image={
            deal.itemImagesUrl?.length > 0
              ? deal.itemImagesUrl[0]
              : PLACEHOLDER_IMAGE
          }
          alt="deal-media"
        />
        <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
          <Grid
            display="grid"
            gridTemplateColumns="max-content 1px max-content"
            gap={2}
            alignItems="center"
            sx={{ mb: 1 }}
          >
            <Typography fontSize="12px" color="#a7a7a7">
              اتفاقية رقم: {deal.prettyId}
            </Typography>
            <Typography fontSize="12px" color="#a7a7a7">
              |
            </Typography>
            <Typography fontSize="12px" color="#a7a7a7">
              {new Date(deal.deliveryDate).toLocaleDateString('ar-EG')}
            </Typography>
          </Grid>
          <Typography fontSize="12px" sx={{ mb: 1 }} fontWeight="bold">
            {deal.itemName} - {deal.type}
          </Typography>
          <Typography fontSize="12px">
            رقم جوال الطرف الآخر:{' '}
            {deal.accountRole.id === 'SELLER'
              ? deal.buyer.mobileNumber
              : deal.seller.mobileNumber}
          </Typography>
          <Typography fontSize="12px" sx={{ mb: 2 }}>
            سعر الجهاز: {deal.itemPrice} ريال
          </Typography>

          <Grid
            display="grid"
            gridAutoFlow="column"
            gridAutoColumns="max-content"
            gap={1}
            alignItems="center"
            justifyContent="end"
          >
            <Chip
              size="small"
              label={deal.status.name}
              sx={{
                backgroundColor: `${
                  // @ts-ignore
                  DealStatusesColors[deal.status.id]
                }`,
                width: 'fit-content',
                color: '#fff',
                fontSize: '12px',
              }}
            />
            <Chip
              size="small"
              label={deal.buyer.id === accountId ? 'مشتري' : 'بائع'}
              sx={{
                backgroundColor: 'rgba(0, 0, 0, 0.08)',
                width: 'fit-content',
                fontSize: '12px',
              }}
            />
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
