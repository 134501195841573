import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Rating,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  Close,
  Edit,
  Folder,
  PanoramaFishEye,
  Person,
  Star,
  SubtitlesOff,
} from '@mui/icons-material';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { findLast } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import LoadingButton from '@mui/lab/LoadingButton';
import { lightenColor } from 'components';
import { DealActions, DealStatuses, DealStatusesColors, IDeal } from 'types';
import { useFetchDeal } from 'queries/deals/FetchDeal';
import { useApproveDeal } from 'queries/deals/ApproveDeal';
import { useCancelDeal } from 'queries/deals/CancelDeal';
import { useDoneDeal } from 'queries/deals/DoneDeal';
import { useNotDeliveredDeal } from 'queries/deals/NotDeliveredDeal';
import { BankNoteRoutes } from 'BankNoteRoutes';
import { useAskForEditDeal } from 'queries/deals/AskForEdit';
import { useRateDealUser } from 'queries/deals/RateDealUser';

type IAskForEditDealForm = {
  reason: string;
};

type IRateDealUserForm = {
  rate: string;
};

export function DealDetails() {
  const { id: prettyId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [isConfirmationCancelOpen, setIsConfirmationCancelOpen] =
    useState(false);
  const [isAskForEditOpen, setIsAskForEditOpen] = useState(false);
  const [isRateUserOpen, setIsRateUserOpen] = useState(false);
  const [rate, setRate] = React.useState<number | null>(1);

  const accountId = Cookies.get('accountId');

  const fetchDeal = useFetchDeal(prettyId);
  const approveDeal = useApproveDeal();
  const cancelDeal = useCancelDeal();
  const doneDeal = useDoneDeal();
  const notDeliveredDeal = useNotDeliveredDeal();
  const askForEditDeal = useAskForEditDeal();
  const rateDealUser = useRateDealUser();

  const deal = fetchDeal.data?.data.data as IDeal;
  const dealId = deal?.id;

  const lastDealStatusInfo = findLast(
    deal?.statuses,
    (statusHistory) => statusHistory.id === deal.status.id,
  );

  const {
    control: askForEditDealFormControl,
    handleSubmit: askForEditDealFormHandleSubmit,
    formState: { errors: askForEditDealFormErrors },
    getValues: askForEditDealFormGetValues,
  } = useForm<IAskForEditDealForm>();

  const {
    control: rateDealUserFormControl,
    handleSubmit: rateDealUserFormHandleSubmit,
  } = useForm<IRateDealUserForm>();

  function onApproveDeal() {
    approveDeal.mutate(dealId);
  }

  function openConfirmationCancelDialog() {
    setIsConfirmationCancelOpen(true);
  }

  function onCloseConfirmationCancelDialog() {
    setIsConfirmationCancelOpen(false);
  }

  function openAskForEditDialog() {
    setIsAskForEditOpen(true);
  }

  function onCloseAskForEditDialog() {
    setIsAskForEditOpen(false);
  }

  function openRateUserDialog() {
    setIsRateUserOpen(true);
  }

  function onCloseRateUserDialog() {
    setIsRateUserOpen(false);
  }

  function onCancelDeal() {
    openConfirmationCancelDialog();
  }

  function onConfirmCancelDeal() {
    cancelDeal.mutate(dealId, {
      onSettled: () => {
        onCloseConfirmationCancelDialog();
      },
    });
  }

  function onAskForEditDeal() {
    openAskForEditDialog();
  }

  function onConfirmAskForEditDeal() {
    askForEditDeal.mutate(
      {
        id: dealId,
        reason: askForEditDealFormGetValues().reason,
        otherReason: askForEditDealFormGetValues().reason,
      },
      {
        onSettled: () => {
          onCloseAskForEditDialog();
        },
      },
    );
  }

  function onRateUser() {
    openRateUserDialog();
  }

  function onConfirmRateDealUser() {
    rateDealUser.mutate(
      {
        dealId,
        rate: rate || 0.5,
      },
      {
        onSettled: () => {
          onCloseRateUserDialog();
        },
      },
    );
  }

  function onPay() {
    navigate(`/profile/deals/${prettyId}/payment-methods`);
  }

  function onDoneDeal() {
    doneDeal.mutate(dealId);
  }

  function onNotDeliveredDeal() {
    notDeliveredDeal.mutate(dealId);
  }

  if (fetchDeal.isSuccess) {
    return (
      <Grid display="flex" flexDirection="column" sx={{ py: 3, px: 2 }}>
        <Box
          display="flex"
          width={1}
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 3 }}
        >
          <Grid
            display="grid"
            gridTemplateColumns="repeat(3, max-content)"
            gap={1}
            alignItems="center"
          >
            <Folder color="primary" fontSize="medium" />
            <Typography fontSize="14px" color="primary" fontWeight="bold">
              تاريخ الاتفاقية:
            </Typography>
            <Typography fontSize="14px" color="primary" fontWeight="lighter">
              {new Date(deal.createdAt).toLocaleDateString('ar-EG')}
            </Typography>
          </Grid>
          {(deal?.status.id === DealStatuses.pending ||
            deal?.status.id === DealStatuses.askForEditing) &&
            deal?.createdBy === accountId && (
              <Button
                variant="contained"
                onClick={() =>
                  navigate({
                    pathname: BankNoteRoutes.upsertDeal,
                    search: `dealId=${prettyId}&step=1`,
                  })
                }
              >
                <Edit sx={{ fontSize: '14px' }} />
                <Typography textAlign="center" sx={{ fontSize: '14px', mx: 1 }}>
                  تعديل الاتفاقية
                </Typography>
              </Button>
            )}
        </Box>

        <Grid
          display="grid"
          sx={{
            border: `1px solid ${lightenColor('#8D8D8D', 0.3)}`,
            borderRight: 0,
            borderLeft: 0,
            gridTemplateColumns: { md: '56% 44%', sm: '1fr' },
            borderBottom: {
              md: `1px solid ${lightenColor('#8D8D8D', 0.3)}`,
              sm: 0,
              xs: 0,
            },
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              borderLeft: {
                md: `1px solid ${lightenColor('#8D8D8D', 0.3)}`,
                sm: 0,
                xs: 0,
              },
              borderBottom: {
                md: 0,
                sm: `1px solid ${lightenColor('#8D8D8D', 0.3)}`,
                xs: `1px solid ${lightenColor('#8D8D8D', 0.3)}`,
              },
              py: 3,
              pl: 2,
            }}
          >
            <Typography mb={2}>معلومات المنتج أو الخدمة</Typography>
            <Grid display="grid" gap={1}>
              <Grid
                display="grid"
                gridTemplateColumns="max-content auto"
                gap={1}
                alignItems="start"
              >
                <PanoramaFishEye
                  sx={{ color: '#8D8D8D', fontSize: '14px', mt: '2px' }}
                />
                <Grid
                  display="grid"
                  gap={2}
                  gridTemplateColumns="max-content auto"
                >
                  <Typography fontSize="14px" color="#8D8D8D">
                    أنت في هذه الاتفاقية:
                  </Typography>
                  <Typography fontSize="14px" color="#8D8D8D">
                    {deal.buyer.id === accountId ? 'مشتري' : 'بائع'}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                display="grid"
                gridTemplateColumns="max-content auto"
                gap={1}
                alignItems="start"
              >
                <PanoramaFishEye
                  sx={{ color: '#8D8D8D', fontSize: '14px', mt: '2px' }}
                />
                <Grid
                  display="grid"
                  gap={2}
                  gridTemplateColumns="max-content auto"
                >
                  <Typography fontSize="14px" color="#8D8D8D">
                    اسم المنتج:
                  </Typography>
                  <Typography fontSize="14px" color="#8D8D8D">
                    {deal.itemName}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                display="grid"
                gridTemplateColumns="max-content auto"
                gap={1}
                alignItems="start"
              >
                <PanoramaFishEye
                  sx={{ color: '#8D8D8D', fontSize: '14px', mt: '2px' }}
                />
                <Grid
                  display="grid"
                  gap={2}
                  gridTemplateColumns="max-content auto"
                >
                  <Typography fontSize="14px" color="#8D8D8D">
                    نوع المنتج او الخدمة:
                  </Typography>
                  <Typography fontSize="14px" color="#8D8D8D">
                    {deal.itemType || 'عام'}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                display="grid"
                gridTemplateColumns="max-content auto"
                gap={1}
                alignItems="start"
              >
                <PanoramaFishEye
                  sx={{ color: '#8D8D8D', fontSize: '14px', mt: '2px' }}
                />
                <Grid
                  display="grid"
                  gap={2}
                  gridTemplateColumns="max-content auto"
                >
                  <Typography fontSize="14px" color="#8D8D8D">
                    السعر او التكلفة:
                  </Typography>
                  <Typography fontSize="14px" color="#8D8D8D">
                    {deal.itemPrice} ريال
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                display="grid"
                gridTemplateColumns="max-content auto"
                gap={1}
                alignItems="start"
              >
                <PanoramaFishEye
                  sx={{ color: '#8D8D8D', fontSize: '14px', mt: '2px' }}
                />
                <Grid
                  display="grid"
                  gap={2}
                  gridTemplateColumns="max-content auto"
                >
                  <Typography fontSize="14px" color="#8D8D8D">
                    تاريخ توصيل المنتج:
                  </Typography>
                  <Typography fontSize="14px" color="#8D8D8D">
                    {new Date(deal.deliveryDate).toLocaleDateString('ar-EG')}
                  </Typography>
                </Grid>
              </Grid>

              {deal.externalItemLink && (
                <Grid
                  display="grid"
                  gap={1}
                  alignItems="start"
                  gridTemplateColumns="max-content auto"
                >
                  <PanoramaFishEye
                    sx={{ color: '#8D8D8D', fontSize: '14px', mt: '2px' }}
                  />
                  <Grid
                    display="grid"
                    gap={2}
                    sx={{
                      gridTemplateColumns: {
                        sm: 'max-content auto',
                        xs: 'max-content 200px',
                      },
                    }}
                  >
                    <Typography fontSize="14px" color="#8D8D8D">
                      رابط صفحة المنتج:
                    </Typography>
                    <Typography
                      fontSize="14px"
                      color="#8D8D8D"
                      sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {deal.externalItemLink}
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {deal.itemDescription && (
                <Grid
                  display="grid"
                  gridTemplateColumns="max-content auto"
                  gap={1}
                  alignItems="start"
                >
                  <PanoramaFishEye
                    sx={{ color: '#8D8D8D', fontSize: '14px', mt: '2px' }}
                  />
                  <Grid
                    display="grid"
                    gap={2}
                    sx={{
                      gridTemplateColumns: {
                        sm: 'max-content auto',
                        xs: 'max-content 200px',
                      },
                    }}
                  >
                    <Typography fontSize="14px" color="#8D8D8D">
                      شروط ومواصفات أخرى:
                    </Typography>
                    <Typography
                      fontSize="14px"
                      color="#8D8D8D"
                      sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {deal.itemDescription}
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {deal.itemImagesUrl && deal.itemImagesUrl.length > 0 && (
                <Grid
                  display="grid"
                  gridTemplateColumns="max-content auto"
                  gap={1}
                  alignItems="start"
                >
                  <PanoramaFishEye
                    sx={{ color: '#8D8D8D', fontSize: '14px', mt: '2px' }}
                  />
                  <Grid display="grid" gap={1}>
                    <Typography fontSize="14px" color="#8D8D8D">
                      صور المنتج:
                    </Typography>
                    <Grid
                      display="grid"
                      width={1}
                      gap={1}
                      sx={{
                        gridTemplateColumns: {
                          sm: 'repeat(auto-fill, 80px)',
                          xs: 'repeat(3, 80px)',
                        },
                      }}
                    >
                      {deal.itemImagesUrl.map((image) => (
                        <img
                          src={image}
                          width="80px"
                          height="80px"
                          alt={image}
                          style={{
                            border: '1px solid rgba(0, 0, 0, 0.07)',
                            borderRadius: '4px',
                          }}
                        />
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            sx={{
              py: 3,
              pr: 2,
            }}
          >
            <Typography mb={2}>معلومات الطرف الآخر</Typography>
            <Grid display="grid" gap={1}>
              <Box
                sx={{
                  display: 'flex',
                  width: '56px',
                  height: '56px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid rgba(0, 0, 0, 0.07)',
                  borderRadius: '4px',
                  objectFit: 'contain',
                }}
              >
                {deal[deal.buyer.id === accountId ? 'seller' : 'buyer']
                  .avatar ? (
                  <img
                    src={
                      deal[deal.buyer.id === accountId ? 'seller' : 'buyer']
                        .avatar || undefined
                    }
                    width="100%"
                    alt={
                      deal[deal.buyer.id === accountId ? 'seller' : 'buyer']
                        .avatar || ''
                    }
                    style={{
                      border: '1px solid rgba(0, 0, 0, 0.07)',
                      borderRadius: '4px',
                    }}
                  />
                ) : (
                  <Person sx={{ fontSize: '44px' }} color="disabled" />
                )}
              </Box>

              <Grid
                display="grid"
                gridTemplateColumns="max-content auto"
                gap={1}
                alignItems="start"
              >
                <PanoramaFishEye
                  sx={{ color: '#8D8D8D', fontSize: '14px', mt: '2px' }}
                />
                <Grid
                  display="grid"
                  gap={2}
                  gridTemplateColumns="max-content auto"
                >
                  <Typography fontSize="14px" color="#8D8D8D">
                    الاسم:
                  </Typography>
                  <Typography fontSize="14px" color="#8D8D8D">
                    {deal[deal.buyer.id === accountId ? 'seller' : 'buyer'].name
                      ? deal[deal.buyer.id === accountId ? 'seller' : 'buyer']
                          .name
                      : 'لايوجد'}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                display="grid"
                gridTemplateColumns="max-content auto"
                gap={1}
                alignItems="start"
              >
                <PanoramaFishEye
                  sx={{ color: '#8D8D8D', fontSize: '14px', mt: '2px' }}
                />
                <Grid
                  display="grid"
                  gap={2}
                  gridTemplateColumns="max-content auto"
                >
                  <Typography fontSize="14px" color="#8D8D8D">
                    رقم الجوال:
                  </Typography>
                  <Typography fontSize="14px" color="#8D8D8D">
                    {
                      deal[deal.buyer.id === accountId ? 'seller' : 'buyer']
                        .mobileNumber
                    }
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                display="grid"
                gridTemplateColumns="max-content auto"
                gap={1}
                alignItems="start"
              >
                <PanoramaFishEye
                  sx={{ color: '#8D8D8D', fontSize: '14px', mt: '2px' }}
                />
                <Grid
                  display="grid"
                  gap={2}
                  gridTemplateColumns="max-content auto"
                >
                  <Typography fontSize="14px" color="#8D8D8D">
                    البريد الالكتروني:
                  </Typography>
                  <Typography fontSize="14px" color="#8D8D8D">
                    {deal[deal.buyer.id === accountId ? 'seller' : 'buyer']
                      .email
                      ? deal[deal.buyer.id === accountId ? 'seller' : 'buyer']
                          .email
                      : 'لايوجد'}
                  </Typography>
                </Grid>
              </Grid>

              {deal[deal.buyer.id === accountId ? 'seller' : 'buyer'].rate
                ?.rate && (
                <Grid
                  display="grid"
                  gridTemplateColumns="max-content auto"
                  gap={1}
                  alignItems="start"
                >
                  <PanoramaFishEye
                    sx={{ color: '#8D8D8D', fontSize: '14px', mt: '2px' }}
                  />
                  <Grid
                    display="grid"
                    gap={2}
                    gridTemplateColumns="max-content auto"
                  >
                    <Typography fontSize="14px" color="#8D8D8D">
                      التقييم:
                    </Typography>
                    <Typography fontSize="14px" color="#8D8D8D">
                      <Rating
                        name="read-only"
                        precision={0.5}
                        value={Number(
                          deal[deal.buyer.id === accountId ? 'seller' : 'buyer']
                            .rate?.rate,
                        )}
                        size="small"
                        readOnly
                      />
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Chip
              size="small"
              label={deal.status.name}
              sx={{
                backgroundColor: `${
                  // @ts-ignore
                  DealStatusesColors[deal.status.id]
                }`,
                width: '100%',
                mt: '24px',
                padding: '0 12px',
                color: '#fff',
                fontSize: '14px',
              }}
            />

            {lastDealStatusInfo?.reason && (
              <Grid
                display="grid"
                gridTemplateColumns="max-content auto"
                gap={1}
                alignItems="center"
                mt={4}
              >
                <PanoramaFishEye sx={{ color: '#8D8D8D', fontSize: '14px' }} />
                <Grid
                  display="grid"
                  gap={2}
                  gridTemplateColumns="max-content auto"
                >
                  <Typography fontSize="14px" color="#8D8D8D">
                    طلب التعديل:
                  </Typography>
                  <Typography fontSize="14px" color="#F38D1E">
                    {lastDealStatusInfo?.reason}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Box>
        </Grid>

        <Grid
          sx={{
            display: 'grid',
            width: '100%',
            gridAutoFlow: 'column',
            gridAutoColumns: '1fr',
            gridGap: '8px',
            mt: '24px',
          }}
        >
          {deal.availableActions?.includes(DealActions.accept) && (
            <LoadingButton
              variant="outlined"
              loadingPosition="start"
              loading={approveDeal.isLoading}
              onClick={onApproveDeal}
            >
              الموافقة علي الاتفاقية
            </LoadingButton>
          )}

          {deal.availableActions?.includes(DealActions.pay) && (
            <Button variant="outlined" onClick={onPay}>
              الدفع
            </Button>
          )}

          {deal.availableActions?.includes(DealActions.askForEditing) && (
            <Button
              color="secondary"
              variant="outlined"
              onClick={onAskForEditDeal}
            >
              طلب تعديل
            </Button>
          )}

          {deal.availableActions?.includes(DealActions.complete) && (
            <LoadingButton
              variant="outlined"
              loadingPosition="start"
              loading={doneDeal.isLoading}
              onClick={onDoneDeal}
            >
              تم الاستلام
            </LoadingButton>
          )}

          {deal.availableActions?.includes(DealActions.notDelivered) && (
            <LoadingButton
              color="error"
              variant="outlined"
              loadingPosition="start"
              loading={notDeliveredDeal.isLoading}
              onClick={onNotDeliveredDeal}
            >
              لم يتم الاستلام
            </LoadingButton>
          )}

          {deal.availableActions?.includes(DealActions.rateUser) && (
            <Button variant="outlined" onClick={onRateUser}>
              تقييم المستخدم
            </Button>
          )}

          {deal.availableActions?.includes(DealActions.cancel) && (
            <Button color="error" variant="outlined" onClick={onCancelDeal}>
              إلغاء الاتفاقية
            </Button>
          )}
        </Grid>

        <Dialog
          open={isAskForEditOpen}
          onClose={onCloseAskForEditDialog}
          aria-labelledby="ask-for-edit-dialog"
          maxWidth="md"
          fullScreen={fullScreen}
        >
          <DialogTitle id="ask-for-edit-dialog-close-btn" sx={{ px: 1, pb: 0 }}>
            <IconButton onClick={onCloseAskForEditDialog}>
              <Close />
            </IconButton>
          </DialogTitle>

          <form
            onSubmit={askForEditDealFormHandleSubmit(onConfirmAskForEditDeal)}
          >
            <Box
              p={3}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ minWidth: { md: '600px', sm: '100%' } }}
            >
              <Edit color="primary" fontSize="large" />

              <Typography
                fontSize="14px"
                color="#0558A9"
                my={3}
                fontWeight="bold"
              >
                طلب تعديل
              </Typography>

              <Grid width={1} display="grid" gap={2} gridTemplateColumns="1fr">
                <Controller
                  name="reason"
                  control={askForEditDealFormControl}
                  rules={{ required: true }}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      multiline
                      minRows={7}
                      variant="filled"
                      label="طلب التعديل"
                      inputProps={{ style: { fontSize: '14px' } }}
                      InputLabelProps={{ shrink: true }}
                      placeholder="أسباب التعديل"
                      error={!!askForEditDealFormErrors.reason}
                      helperText={
                        askForEditDealFormErrors.reason
                          ? 'هذا الحقل مطلوب'
                          : ' '
                      }
                    />
                  )}
                />
              </Grid>

              <Grid
                sx={{
                  display: 'grid',
                  width: '100%',
                  gridAutoFlow: 'column',
                  gridAutoColumns: '1fr',
                  gridGap: '8px',
                  mt: '24px',
                }}
              >
                <LoadingButton
                  color="primary"
                  variant="contained"
                  loadingPosition="start"
                  loading={askForEditDeal.isLoading}
                  type="submit"
                >
                  تأكيد
                </LoadingButton>
              </Grid>
            </Box>
          </form>
        </Dialog>

        <Dialog
          open={isRateUserOpen}
          onClose={onCloseRateUserDialog}
          aria-labelledby="rate-user-dialog"
          maxWidth="md"
          fullScreen={fullScreen}
        >
          <DialogTitle id="rate-user-dialog-close-btn" sx={{ px: 1, pb: 0 }}>
            <IconButton onClick={onCloseRateUserDialog}>
              <Close />
            </IconButton>
          </DialogTitle>

          <form onSubmit={rateDealUserFormHandleSubmit(onConfirmRateDealUser)}>
            <Box
              p={3}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{ minWidth: { md: '600px', sm: '100%' } }}
            >
              <Star color="primary" fontSize="large" />

              <Typography
                fontSize="14px"
                color="#0558A9"
                my={3}
                fontWeight="bold"
              >
                تقييمك للطرف الآخر في هذه الاتفاقية
              </Typography>
              <Box width={1} display="flex" justifyContent="center">
                <Controller
                  name="rate"
                  control={rateDealUserFormControl}
                  render={() => (
                    <Rating
                      size="large"
                      value={rate}
                      precision={0.5}
                      onChange={(event, newRate) => {
                        setRate(newRate);
                      }}
                      sx={{ width: 'fit-content' }}
                    />
                  )}
                />
              </Box>

              <Grid
                sx={{
                  display: 'grid',
                  width: '100%',
                  gridAutoFlow: 'column',
                  gridAutoColumns: '1fr',
                  gridGap: '8px',
                  mt: 5,
                }}
              >
                <LoadingButton
                  color="primary"
                  variant="contained"
                  loadingPosition="start"
                  loading={rateDealUser.isLoading}
                  type="submit"
                >
                  تأكيد
                </LoadingButton>
              </Grid>
            </Box>
          </form>
        </Dialog>

        <Dialog
          open={isConfirmationCancelOpen}
          onClose={onCloseConfirmationCancelDialog}
          aria-labelledby="confirm-cancel-dialog"
          maxWidth="md"
          fullScreen={fullScreen}
        >
          <DialogTitle
            id="confirm-cancel-dialog-close-btn"
            sx={{ px: 1, pb: 0 }}
          >
            <IconButton onClick={onCloseConfirmationCancelDialog}>
              <Close />
            </IconButton>
          </DialogTitle>

          <Box
            p={3}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{ minWidth: { md: '600px', sm: '100%' } }}
          >
            <SubtitlesOff color="error" fontSize="large" />

            <Typography my={3} fontSize="14px" color="error" fontWeight="bold">
              هل تريد بالفعل الغاء الإتفاقية؟
            </Typography>

            <Grid
              sx={{
                display: 'grid',
                width: '100%',
                gridAutoFlow: 'column',
                gridAutoColumns: '1fr',
                gridGap: '8px',
                mt: '24px',
              }}
            >
              <LoadingButton
                color="error"
                variant="contained"
                loadingPosition="start"
                loading={cancelDeal.isLoading}
                onClick={onConfirmCancelDeal}
              >
                نعم, أريد الغاء الاتفاقية
              </LoadingButton>

              <Button
                color="primary"
                variant="outlined"
                onClick={onCloseConfirmationCancelDialog}
              >
                لا, أريد الغاء الاتفاقية
              </Button>
            </Grid>
          </Box>
        </Dialog>
      </Grid>
    );
  }

  return <div>Loading...</div>;
}
