import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

type IRegister = {
  mobileNumber: string;
  name?: string;
  email?: string;
};

export function useRegister() {
  const {
    error: err,
    data,
    isLoading,
    isSuccess,
    mutate,
    reset,
  } = useMutation({
    mutationKey: ['otp'],
    mutationFn: (request: IRegister) =>
      axios.post(
        `${import.meta.env.VITE_API_BASE_URL}/auth/register`,
        request,
        {
          headers: { 'accept-language': 'ar' },
        },
      ),
  });

  const error = err as AxiosError;

  return {
    error,
    data,
    isLoading,
    isSuccess,
    mutate,
    reset,
  };
}
