export async function base64toFile(base64String: string, fileName: string) {
  const base64Response = await fetch(base64String);
  const blob = await base64Response.blob();
  const fileExtension =
    blob.type.lastIndexOf('/') < 1 ? null : blob.type.split('/').slice(-1);

  return new File([blob], `${fileName}.${fileExtension}`, { type: blob.type });
}

export async function convertImageUrlToBase64(imgUrl: string) {
  const imageBase64 = await new Promise<string>((resolve) => {
    const image = new Image();
    image.src = imgUrl;
    image.crossOrigin = 'anonymous';

    image.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      let width = image.width;
      let height = image.height;

      if (width > height) {
        if (width > 450) {
          height *= 450 / width;
          width = 450;
        }
      } else if (height > 450) {
        width *= 450 / height;
        height = 450;
      }

      canvas.height = height;
      canvas.width = width;

      ctx?.drawImage(image, 0, 0, width, height);

      resolve(canvas.toDataURL());
    };
  });

  return imageBase64;
}

export function convertArabicNumbersToEnglish(stringNumber: string) {
  return stringNumber
    .replace(/[\u0660-\u0669]/g, (c: string) =>
      (c.charCodeAt(0) - 0x0660).toString(),
    )
    .replace(/[\u06f0-\u06f9]/g, (c: string) =>
      (c.charCodeAt(0) - 0x06f0).toString(),
    );
}
