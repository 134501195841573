export enum BankNoteRoutes {
  base = '/',
  login = '/login',
  totp = '/login/totp',
  signup = '/signup',
  about = '/about',
  contactUs = '/contact-us',
  upsertDeal = '/upsert-deal',
  profile = '/profile',
}
