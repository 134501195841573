import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { useSnackbar } from 'notistack';
import { IAccount, IBankNoteError, IDeal } from 'types';

type ICreateDealRequest = Omit<IDeal, 'buyer' | 'seller' | 'accountRole'> & {
  oppositeAccount: IAccount;
  accountRole: 'SELLER' | 'BUYER';
};

type ICreateDealResponse = {
  data: IDeal;
};

export function useCreateDeal() {
  // Get QueryClient from the context
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const {
    error: err,
    data,
    isLoading,
    isSuccess,
    mutate,
    reset,
  } = useMutation({
    mutationKey: ['create-deal'],
    mutationFn: (request: Partial<ICreateDealRequest>) => {
      return axios.post<ICreateDealResponse>(
        `${import.meta.env.VITE_API_BASE_URL}/deals`,
        request,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
            'accept-language': 'ar',
          },
        },
      );
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['fetch-user-deals'] }),
    onError: (requestError: AxiosError<IBankNoteError>) => {
      enqueueSnackbar(
        requestError?.response?.data?.errors[0].message ||
          requestError?.response?.data?.message ||
          '',
        {
          variant: 'error',
        },
      );
    },
  });

  const error = err as AxiosError;

  return {
    error,
    data,
    isLoading,
    isSuccess,
    mutate,
    reset,
  };
}
