import { useState } from 'react';
import styled from 'styled-components';
import {
  IFileUrlPreviewProps,
  ILocalFilePreviewProps,
} from './FilePreview.types';

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

function Base({ name, url, onClick }: IFileUrlPreviewProps) {
  return <Image data-testid={`image-${name}`} src={url} onClick={onClick} />;
}

export function FileUrlPreview(props: IFileUrlPreviewProps) {
  return <Base {...props} />;
}

export function FilePreview({ file, ...rest }: ILocalFilePreviewProps) {
  const [filePreview, setFilePreview] = useState<string>('');

  function getFileFormat(name: string) {
    return name?.lastIndexOf('.') < 1
      ? null
      : name?.split('.')?.slice(-1)?.[0]?.toLowerCase();
  }

  switch (getFileFormat(file.name)) {
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'pdf': {
      const reader = new FileReader();

      reader.onload = () => {
        const path = reader.result;
        setFilePreview(path as string);
      };

      reader.readAsDataURL(file);
      break;
    }

    default: {
      break;
    }
  }

  return <Base name={file.name} url={filePreview} {...rest} />;
}
