export enum DealStatuses {
  pending = 'PENDING',
  askForEditing = 'ASK_FOR_EDITING',
  rejected = 'REJECTED',
  canceled = 'CANCELED',
  accepted = 'ACCEPTED',
  waitingForPayment = 'WAITING_FOR_PAYMENT',
  waitingForPaymentApproval = 'WAITING_FOR_PAYMENT_APPROVAL',
  active = 'ACTIVE',
  done = 'DONE',
  reviewed = 'REVIEWED',
}

export enum DealStatusesColors {
  PENDING = '#F38D1E',
  ASK_FOR_EDITING = '#F38D1E',
  REJECTED = '#D32F2F',
  CANCELED = '#D32F2F',
  WAITING_FOR_PAYMENT = '#309C9C',
  WAITING_FOR_PAYMENT_APPROVAL = '#309C9C',
  ACTIVE = '#198754',
  DONE = '#E49722',
  REVIEWED = '#0558A9',
}
