import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { IDeal, IPagination } from 'types';

type IUserDeals = {
  data: IDeal[];
  meta: IPagination;
};

export function useFetchUserDeals(page = 1) {
  const {
    error: err,
    data,
    isLoading,
    isSuccess,
    isPreviousData,
  } = useQuery({
    queryKey: ['fetch-user-deals', page],
    queryFn: ({ signal }) =>
      axios.get<IUserDeals>(
        `${import.meta.env.VITE_API_BASE_URL}/deals?page=${page}&size=10`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
            'accept-language': 'ar',
          },
          signal,
        },
      ),
    keepPreviousData: true,
    staleTime: Infinity,
    retry: 3,
  });

  const error = err as AxiosError;

  return {
    error,
    data,
    isLoading,
    isSuccess,
    isPreviousData,
  };
}
