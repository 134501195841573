import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';

type IOtp = {
  mobileNumber: string;
};

export function useOtp() {
  const {
    error: err,
    data,
    isLoading,
    isSuccess,
    mutate,
    reset,
  } = useMutation({
    mutationKey: ['otp'],
    mutationFn: (request: IOtp) =>
      axios.post(
        `${import.meta.env.VITE_API_BASE_URL}/auth/one-time-password`,
        request,
        {
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
            'accept-language': 'ar',
          },
        },
      ),
  });

  const error = err as AxiosError;

  return {
    error,
    data,
    isLoading,
    isSuccess,
    mutate,
    reset,
  };
}
