import axios, { AxiosError } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { useSnackbar } from 'notistack';
import { IBankNoteError } from 'types';

type IAskForEdit = {
  id: string;
  reason: string;
  otherReason: string;
};

export function useAskForEditDeal() {
  // Get QueryClient from the context
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const {
    error: err,
    data,
    isLoading,
    isSuccess,
    mutate,
    reset,
  } = useMutation({
    mutationKey: ['ask-for-edit-deal'],
    mutationFn: (request: IAskForEdit) => {
      return axios.post(
        `${import.meta.env.VITE_API_BASE_URL}/deals/${request.id}/ask-for-edit`,
        {
          reason: request.reason,
          otherReason: request.otherReason,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
            'accept-language': 'ar',
          },
        },
      );
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ['fetch-deal'] }).then(() =>
        queryClient
          .invalidateQueries({
            queryKey: ['fetch-personal-profile'],
          })
          .then(() =>
            queryClient.invalidateQueries({ queryKey: ['fetch-user-deals'] }),
          ),
      ),
    onError: (requestError: AxiosError<IBankNoteError>) => {
      enqueueSnackbar(
        requestError?.response?.data?.errors[0].message ||
          requestError?.response?.data?.message ||
          '',
        {
          variant: 'error',
        },
      );
    },
  });

  const error = err as AxiosError;

  return {
    error,
    data,
    isLoading,
    isSuccess,
    mutate,
    reset,
  };
}
