import {
  Box,
  Button,
  Dialog,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import Cookies from 'js-cookie';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import HAND_CASH from 'assests/images/home_1.svg';
import PRODUCT_BOX from 'assests/images/step_1.svg';
import HAND_SHAKE from 'assests/images/step_2.svg';
import AGREE_HAND from 'assests/images/step_3.svg';
import SAFE from 'assests/images/newsletter.svg';
// import { BankNoteRoutes } from 'BankNoteRoutes';
import { Login, Totp } from '../authentication';
import 'animate.css/animate.min.css';

export function Home() {
  const navigate = useNavigate();

  const [isLoginDialogOpen, setIsLoginDialogOpen] = useState(false);
  const [isTotpDialogOpen, setIsTotpDialogOpen] = useState(false);

  // const isUserLoggedIn = Cookies.get('token');

  function onCloseLoginDialog() {
    setIsLoginDialogOpen(false);
  }

  // function onCreateDeal() {
  //   if (isUserLoggedIn) {
  //     navigate(BankNoteRoutes.upsertDeal);
  //   } else {
  //     setIsLoginDialogOpen(true);
  //   }
  // }

  function openTotpDialog() {
    setIsTotpDialogOpen(true);
    onCloseLoginDialog();
  }

  function onCloseTotpDialog() {
    setIsTotpDialogOpen(false);
  }

  function onLearnMore() {
    navigate('/about');
  }

  return (
    <Box>
      <Grid
        className="home_first_section"
        display="grid"
        width={1}
        gap="2%"
        sx={{
          gridTemplateColumns: { xs: '1fr', sm: '40% 60%' },
          padding: { xs: '0 8px', sm: 0 },
        }}
      >
        <Box
          className="pt_60 max_370"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Typography
            textAlign="right"
            className="text_bold blue_color text_center"
            sx={{ mb: 3, fontSize: { xs: '60px', sm: '60px', md: '70px' } }}
          >
            اشتر براحتك<br></br>
            وانت<span className="orange_color"> مطمن</span>
          </Typography>

          <Typography
            textAlign="right"
            color="#000000"
            sx={{ mb: 3, fontSize: { xs: '14px', sm: '16px', md: '20px' } }}
          >
            بنكنوت خيارك المريح للدفع، اشتر من أي تاجر في أي مكان وادفع عن طريق
            بنكنوت، راح يتم تعليق المبلغ الى حصولك على المنتج أو الخدمة.
          </Typography>

          <Grid
            sx={{
              display: 'grid',
              gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
            }}
            gap={1}
            width={1}
            justifyContent="center"
            alignItems="center"
          >
            {/* <Button
              variant="contained"
              className="orangeBtn contained"
              fullWidth
              sx={{ my: { xs: 1, sm: 2 }, display: 'block' }}
              onClick={onCreateDeal}
            >
              اتفاقية جديدة
            </Button> */}

            <Button
              variant="outlined"
              className="blueBtn outline"
              fullWidth
              sx={{ my: { xs: 0, sm: 2 }, display: 'block' }}
              onClick={onLearnMore}
            >
              اعرف أكثر عن بنكنوت
            </Button>
          </Grid>
        </Box>
        <img
          src={HAND_CASH}
          width="100%"
          alt="hand-cash"
          className="home_first_img"
        />
      </Grid>

      <AnimationOnScroll animateIn="animate__fadeIn">
        <Grid
          className="home_section_2"
          display="grid"
          gap={1}
          sx={{ marginTop: { xs: 10, sm: 3 }, mx: { xs: 0, sm: 8 } }}
        >
          <Typography
            className="section_2_text_1"
            sx={{
              fontSize: {
                xs: '16px',
                md: '24px',
              },
            }}
          >
            .. أشـــتر أي شيء مـــن أي مـكــــان بــأمــــان
          </Typography>

          <Grid
            display="grid"
            gap={3}
            gridTemplateColumns="auto"
            alignItems="baseline"
            sx={{
              gridTemplateColumns: {
                xs: '1fr',
                md: 'auto',
              },
            }}
          >
            <Typography
              className="section_2_text_2"
              sx={{
                fontSize: {
                  xs: '16px',
                  md: '24px',
                },
              }}
            >
              في ثلاث خطوات فقط
            </Typography>
          </Grid>
        </Grid>
      </AnimationOnScroll>

      <Grid
        display="grid"
        width={1}
        alignItems="center"
        gap={0}
        marginBottom="40px"
        sx={{
          gridTemplateColumns: {
            xs: '1fr',
            md: '33.3% 33.3% 33.3%',
          },
        }}
      >
        <AnimationOnScroll animateIn="animate__fadeInDown">
          <Box
            className="step_container"
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{
              marginTop: {
                xs: '24px',
                sm: '28px',
                md: '32px',
              },
            }}
          >
            <img
              src={PRODUCT_BOX}
              alt="product-box"
              style={{
                width: '250px',
              }}
            />

            <Typography
              fontSize="20px"
              color="primary"
              textAlign="center"
              className="step_title"
              sx={{ textDecoration: 'underline', marginBottom: '12px' }}
            >
              الخطوة الأولى
            </Typography>

            <Typography
              fontSize="14px"
              color="secondary"
              textAlign="center"
              lineHeight={1.7}
              width={170}
              className="step_text"
            >
              ابدأ اتفاقية جديدة وحدد المنتج أو الخدمة
            </Typography>
          </Box>
        </AnimationOnScroll>

        <AnimationOnScroll delay={300} animateIn="animate__fadeInDown">
          <Box
            className="step_container"
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{
              marginTop: {
                xs: '24px',
                sm: '28px',
                md: '32px',
              },
            }}
          >
            <img
              src={HAND_SHAKE}
              alt="hand-shake"
              style={{
                width: '250px',
              }}
            />

            <Typography
              fontSize="20px"
              color="primary"
              textAlign="center"
              className="step_title"
              sx={{ textDecoration: 'underline', marginBottom: '12px' }}
            >
              الخطوة الثانية
            </Typography>

            <Typography
              fontSize="14px"
              color="secondary"
              textAlign="center"
              lineHeight={1.7}
              width={170}
              className="step_text"
            >
              أدخل بيانات الطرف الثاني
            </Typography>
          </Box>
        </AnimationOnScroll>
        <AnimationOnScroll delay={600} animateIn="animate__fadeInDown">
          <Box
            className="step_container"
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{
              marginTop: {
                xs: '24px',
                sm: '28px',
                md: '32px',
              },
            }}
          >
            <img
              src={AGREE_HAND}
              alt="agree-hand"
              style={{
                width: '250px',
              }}
            />

            <Typography
              fontSize="20px"
              color="primary"
              textAlign="center"
              className="step_title"
              sx={{ textDecoration: 'underline', marginBottom: '12px' }}
            >
              الخطوة الثالثة
            </Typography>

            <Typography
              fontSize="14px"
              color="secondary"
              textAlign="center"
              lineHeight={1.7}
              width={170}
              className="step_text"
            >
              أرسل الاتفاقية وتتبعها بكل سهولة
            </Typography>
          </Box>
        </AnimationOnScroll>
      </Grid>

      <Paper
        elevation={7}
        className="newsLatter"
        sx={{
          display: 'flex',
          width: { xs: '100%', md: '100%' },
          margin: '0 auto',
        }}
      >
        <Box
          display="grid"
          justifyContent="space-between"
          width={1}
          position="relative"
          sx={{
            p: {
              xs: '20px',
              md: '40px',
            },
            gridTemplateColumns: {
              xs: '1fr',
              md: '50% 50%',
            },
          }}
        >
          <AnimationOnScroll animateIn="animate__fadeInRight">
            <Box
              display="flex"
              flexDirection="column"
              className="newsletter_text"
            >
              <Typography
                sx={{
                  fontSize: { xs: '18px', md: '24px' },
                }}
                fontWeight="bold"
                marginBottom={1}
              >
                اشترك في نشرتنا
              </Typography>

              <Typography
                fontSize="14px"
                fontWeight="lighter"
                width="90%"
                marginBottom={2}
              >
                لا يفوتك شي، آخر الأخبار راح توصلك أول بأول
              </Typography>

              <TextField
                placeholder="البريد الإلكتروني"
                InputProps={{
                  startAdornment: <Button variant="contained">اشترك</Button>,
                }}
                inputProps={{
                  sx: { padding: '4px' },
                }}
              />
            </Box>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn="animate__fadeInLeft">
            <Box className="newsletter_img">
              <img
                src={SAFE}
                alt="safe"
                style={{
                  width: '100%',
                }}
              />
            </Box>
          </AnimationOnScroll>
        </Box>
      </Paper>

      {isLoginDialogOpen && (
        <Dialog
          open={isLoginDialogOpen}
          onClose={onCloseLoginDialog}
          aria-labelledby="login-dialog"
          maxWidth="md"
        >
          <Login
            isTotpDialogOpen={isTotpDialogOpen}
            openTotpDialog={openTotpDialog}
          />
        </Dialog>
      )}

      <Dialog
        open={isTotpDialogOpen}
        onClose={onCloseTotpDialog}
        aria-labelledby="totp-dialog"
        maxWidth="md"
      >
        <Totp />
      </Dialog>
    </Box>
  );
}
