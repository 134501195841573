import { Grid, Box, Typography } from '@mui/material';
import ABOUT_1 from 'assests/images/about_1.svg';
import ABOUT_2 from 'assests/images/about_2.svg';
import ABOUT_3 from 'assests/images/about_3.svg';
import ABOUT_4 from 'assests/images/about_4.svg';
import ABOUT_5 from 'assests/images/about_5.svg';

export function AboutUs() {
  return (
    <Box sx={{ p: 3 }}>
      <Grid
        className="about_first_section"
        display="grid"
        width={1}
        gap="2%"
        sx={{
          gridTemplateColumns: { xs: '1fr', sm: '60% 40%' },
          padding: { xs: '0', sm: '0' },
        }}
      >
        <Box
          className="pt_60"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Box>
            <Typography className="about_head">
              بنكنوت منصة إلكترونية لتوثيق وإتمام الاتفاقات
            </Typography>
            <Typography className="about_sub">
              <span>التي تتم عبر الانترنت بائع </span>
              أو مشتري كل تعاملاتك مع بنكنوت تتم بطريقة آمنة
            </Typography>
          </Box>
        </Box>
        <Box
          className="pt_60"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <img
            src={ABOUT_1}
            alt=""
            style={{
              width: '100%',
              verticalAlign: 'text-top',
            }}
          />
        </Box>
      </Grid>
      <Grid
        className="about_second_section"
        display="grid"
        width={1}
        gap="2%"
        sx={{
          gridTemplateColumns: { xs: '1fr', sm: '50% 50%' },
          padding: { xs: '0', sm: '0 50px' },
        }}
      >
        <Box
          className="pt_60"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <dl>
            <dt>
              <Typography color="primary" fontSize="16px" fontWeight="bold">
                بنكنوت يحفظ حقك
              </Typography>
            </dt>
            <dd>
              <Typography fontSize="16px" sx={{ mt: 1 }}>
                بأمن لك عملية الشراء أو طلب الخدمات
                <span> سواء من أفراد أو متاجر عن</span>
                <span>طريق تعليق المبلغ المدفوع إلى حين إتمام المتفق عليه</span>
              </Typography>
              <img
                src={ABOUT_2}
                alt=""
                style={{
                  width: '200px',
                  margin: '25px auto',
                  display: 'block',
                  verticalAlign: 'text-top',
                }}
              />
            </dd>
          </dl>
        </Box>
        <Box
          className="pt_60"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <dl>
            <dt>
              <Typography
                color="primary"
                fontSize="16px"
                fontWeight="bold"
                sx={{ mt: 2 }}
              >
                بنكنوت يحميك
              </Typography>
            </dt>
            <dd>
              <Typography fontSize="16px" sx={{ mt: 1 }}>
                نضمن حمايتك من عمليات الاحتيال المحتلمة، لا تدفع مقدماً لشخص
                <span>مجهول او متجر غير موثوق لغرض الشراء.</span>
              </Typography>
              <img
                src={ABOUT_3}
                alt=""
                style={{
                  width: '200px',
                  margin: '25px auto',
                  display: 'block',
                  verticalAlign: 'text-top',
                }}
              />
            </dd>
          </dl>
        </Box>
        <Box
          className="pt_20"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <dl>
            <dt>
              <Typography
                color="primary"
                fontSize="16px"
                fontWeight="bold"
                sx={{ mt: 2 }}
              >
                بنكنوت يفهمك
              </Typography>
            </dt>
            <dd>
              <Typography fontSize="16px" sx={{ mt: 1 }}>
                نفهمك ونفهم احتياجك
                <span>قد تلجأ للتعامل مع متاجر أو أفراد غير</span>
                <span>موثوقين بدون طريقة دفع واضحة،</span>
                <span> الآن تقدر تتسوق وتحول بكل راحة </span>
                <span>بال عن طريق بنكنوت.</span>
              </Typography>
              <img
                src={ABOUT_4}
                alt=""
                style={{
                  width: '200px',
                  margin: '25px auto',
                  display: 'block',
                  verticalAlign: 'text-top',
                }}
              />
            </dd>
          </dl>
        </Box>
        <Box
          className="pt_20"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <dl>
            <dt>
              <Typography
                color="primary"
                fontSize="16px"
                fontWeight="bold"
                sx={{ mt: 2 }}
              >
                الوضوح و المصداقية
              </Typography>
            </dt>
            <dd>
              <Typography fontSize="16px" sx={{ mt: 1 }}>
                في بنكنوت تجد الوضوح في
                <span> الاتفاقات والمصداقية في الاستلام و الإنجاز</span>
              </Typography>
              <img
                src={ABOUT_5}
                alt=""
                style={{
                  width: '200px',
                  margin: '25px auto',
                  display: 'block',
                  verticalAlign: 'text-top',
                }}
              />
            </dd>
          </dl>
        </Box>
      </Grid>
    </Box>
  );
}
