import React from 'react';
import styled from 'styled-components';
import { Box, IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { InfiniteLoadingBar } from '../infiniteLoadingBar';
import { FilePreview as Preview } from '../filePreview';
import {
  FileUploadingStatus,
  FileUploadingStatusLocale,
} from './FilesUploader.types';

const FilePreviewContainer = styled(Box)`
  display: flex;
  border: 1px solid #cbcbcb;
  border-radius: 4px;
`;

interface IFilePreviewProps {
  /**
   * File object info
   */
  file: File;
  /**
   * The file index in the files array
   */
  index: number;
  /**
   * Method to remove uploaded file
   */
  removeFile: (index: number) => void;
  /**
   * Uploading status text
   */
  status?: FileUploadingStatus;
}

function FilePreview({
  file,
  index,
  removeFile,
  status = FileUploadingStatus.pending,
}: IFilePreviewProps) {
  const id = file.name.replace('.', '_');
  const enableRemoveFile =
    status !== FileUploadingStatus.uploading &&
    status !== FileUploadingStatus.success;

  let statusColor;

  switch (status) {
    case FileUploadingStatus.success: {
      statusColor = 'success';
      break;
    }

    case FileUploadingStatus.failed: {
      statusColor = 'error';
      break;
    }

    case FileUploadingStatus.pending:
    default: {
      statusColor = 'text.secondary';
      break;
    }
  }

  return (
    <FilePreviewContainer
      id={id}
      data-testid={id}
      width={1}
      flexDirection="column"
      height="max-content"
      overflow="hidden"
    >
      <Box
        display="flex"
        width={1}
        height={84}
        justifyContent="center"
        alignItems="center"
      >
        <Preview file={file} disablePointerEvents />
      </Box>

      <Box
        display="flex"
        width={1}
        py={1}
        px={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          display="flex"
          width={1}
          flexDirection="column"
          justifyContent="space-between"
        >
          <Typography
            data-for={`doc-name-${id}`}
            data-tip
            fontWeight="bold"
            width={1}
            fontSize="14px"
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {file.name}
          </Typography>

          <Box display="flex" width={1} height="18px" alignItems="center">
            {status === FileUploadingStatus.uploading ? (
              <InfiniteLoadingBar />
            ) : (
              <Typography color={statusColor} fontSize="14px">
                {FileUploadingStatusLocale[status]}
              </Typography>
            )}
          </Box>
        </Box>

        <Box ml={1} />

        <IconButton
          id={`${id}-delete-btn`}
          data-testid={`${id}-delete-btn`}
          onClick={() => removeFile(index)}
          disabled={!enableRemoveFile}
        >
          <Close />
        </IconButton>
      </Box>
    </FilePreviewContainer>
  );
}

export default FilePreview;
