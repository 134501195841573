import { Box, Grid, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useDropzone } from 'react-dropzone';
import LoadingButton from '@mui/lab/LoadingButton';
import { getDownloadURL, ref, uploadString } from 'firebase/storage';
import { isEmpty } from 'lodash';
import Cookies from 'js-cookie';
import { useFetchPersonalProfile } from 'queries/profile/FetchPersonalProfile';
import { useUpdatePersonalProfile } from 'queries/profile/UpdatePersonalProfile';
import { FilePreview } from 'components';
import { storage } from 'App';
import { base64toFile, convertImageUrlToBase64, numberOnly } from 'utilities';
import UPLOAD_PLACEHOLDER from 'assests/images/profile_1.svg';

type IProfileImage = {
  name: string;
  content: string;
};

type IProfileForm = {
  name: string;
  birthdate: string;
  idNumber: string;
  ibanNumber: string;
  mobileNumber: string;
  email: string | null;
};

export function PersonalInfo() {
  const [profilePictureFile, setProfilePictureFile] = useState<File>();
  const [profilePicture, setProfilePicture] = useState<IProfileImage>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchPersonalProfile = useFetchPersonalProfile();
  const updatePersonalProfile = useUpdatePersonalProfile();

  function onUploadProfilePicture(files: File[]) {
    setProfilePictureFile(files[0]);
    const reader = new FileReader();

    files.forEach((file) => {
      reader.readAsDataURL(file);

      reader.onload = () => {
        setProfilePicture({
          name: file.name,
          content: reader.result as string,
        });
      };
    });
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IProfileForm>();

  function onSubmit(data: IProfileForm) {
    setIsSubmitting(true);

    let avatar: string | undefined | null = null;
    const promises: any[] = [];

    if (!isEmpty(profilePictureFile)) {
      const base64string = profilePicture?.content.split(',').pop() as string;

      const storageRef = ref(
        storage,
        `/profile-images/${fetchPersonalProfile.data?.data.data.mobileNumber}-${profilePictureFile?.name}`,
      );

      promises.push(
        uploadString(storageRef, base64string, 'base64').then(async () => {
          await getDownloadURL(storageRef).then((url) => {
            avatar = url;
          });
        }),
      );
    } else {
      avatar = fetchPersonalProfile.data?.data.data.avatar;
    }

    Promise.all(promises).then(() => {
      updatePersonalProfile.mutate({
        id: fetchPersonalProfile.data?.data.data.id as string,
        name: data.name,
        email: data.email,
        mobileNumber: fetchPersonalProfile.data?.data.data
          .mobileNumber as string,
        governmentId: Number(data.idNumber),
        IBAN: data.ibanNumber,
        birthdate: new Date(data.birthdate).toLocaleDateString('en-US'),
        avatar: avatar || null,
      });
    });
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onUploadProfilePicture,
    maxSize: 10000000,
  });

  useEffect(() => {
    fetchPersonalProfile.refetch().then(() => {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!updatePersonalProfile.isLoading) {
      setIsSubmitting(false);
    }
  }, [updatePersonalProfile.isLoading]);

  useEffect(() => {
    if (fetchPersonalProfile.isSuccess) {
      reset({
        name: fetchPersonalProfile.data?.data.data.name,
        mobileNumber: fetchPersonalProfile.data?.data.data.mobileNumber,
        email: fetchPersonalProfile.data?.data.data.email,
        birthdate: fetchPersonalProfile.data?.data.data.birthdate,
        ibanNumber: fetchPersonalProfile.data?.data.data.IBAN,
        idNumber: fetchPersonalProfile.data?.data.data.governmentId?.toString(),
      });

      Cookies.set(
        'accountName',
        fetchPersonalProfile.data?.data.data.name as string,
        { expires: 30 },
      );
      Cookies.set(
        'accountMobileNumber',
        fetchPersonalProfile.data?.data.data.mobileNumber as string,
        { expires: 30 },
      );
      Cookies.set(
        'accountId',
        fetchPersonalProfile.data?.data.data.id as string,
        { expires: 30 },
      );
      Cookies.set(
        'accountBalance',
        fetchPersonalProfile.data?.data.data.availableBalance?.toString() as string,
        { expires: 30 },
      );
      Cookies.set(
        'accountRate',
        fetchPersonalProfile.data?.data.data.rateNumber?.toString() as string,
        { expires: 30 },
      );

      if (fetchPersonalProfile.data?.data.data.avatar) {
        convertImageUrlToBase64(
          fetchPersonalProfile.data?.data.data.avatar || '',
        ).then(async (avatarBase64) => {
          const avatarFile = await base64toFile(avatarBase64, 'avatar-img');

          setProfilePictureFile(avatarFile);
        });
      }
    }
  }, [
    fetchPersonalProfile.data?.data.data.availableBalance,
    fetchPersonalProfile.data?.data.data.id,
    fetchPersonalProfile.data?.data.data.rateNumber,
    fetchPersonalProfile.data?.data.data.IBAN,
    fetchPersonalProfile.data?.data.data.birthdate,
    fetchPersonalProfile.data?.data.data.email,
    fetchPersonalProfile.data?.data.data.governmentId,
    fetchPersonalProfile.data?.data.data.mobileNumber,
    fetchPersonalProfile.data?.data.data.name,
    fetchPersonalProfile.data?.data.data.avatar,
    fetchPersonalProfile.isSuccess,
    reset,
  ]);

  let idNumberErrorText = ' ';

  if (errors.idNumber?.type === 'required') {
    idNumberErrorText = errors.idNumber?.message as string;
  } else if (errors.idNumber?.type === 'pattern') {
    idNumberErrorText = errors.idNumber?.message as string;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        px: { xs: 4, sm: 4 },
        py: { xs: '26px', sm: 8 },
      }}
      className="Taps_content_box profile"
    >
      <Box className="content_border">
        <Grid
          display="grid"
          gridTemplateColumns="max-content max-content"
          gap={2}
          alignItems="center"
          sx={{ mt: 6, mb: 4 }}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100px',
              height: '100px',
              alignItems: 'center',
              justifyContent: 'center',
              objectFit: 'contain',
            }}
          >
            {profilePictureFile ? (
              <FilePreview file={profilePictureFile} />
            ) : (
              <img
                src={UPLOAD_PLACEHOLDER}
                width="100%"
                alt="upload"
                className="upload"
              />
            )}
          </Box>

          <input
            id="profile-image-uploader"
            data-testid="profile-image-uploader"
            {...getInputProps()}
          />

          <Box
            {...getRootProps()}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              cursor: 'pointer',
            }}
          >
            <Typography color="#000" fontSize="16px">
              إضافة / تعديل الصورة الشخصية
            </Typography>
          </Box>
        </Grid>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid width={1} display="grid" gap={2} gridTemplateColumns="1fr">
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="standard"
                  label="اسم المستخدم ( حقل مطلوب )"
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.name}
                  helperText={errors.name ? 'هذا الحقل مطلوب' : ' '}
                />
              )}
            />

            <Controller
              name="birthdate"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    {...field}
                    inputFormat="DD/MM/YYYY"
                    disableFuture
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="تاريخ الميلاد ( حقل مطلوب )"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: 'من فضلك, ضع تاريخ الميلاد (dd/mm/yyyy)',
                        }}
                        error={!!errors.birthdate}
                        helperText={errors.birthdate ? 'هذا الحقل مطلوب' : ' '}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />

            <Controller
              name="idNumber"
              control={control}
              rules={{
                required: { value: true, message: 'هذا الحقل مطلوب' },
                pattern: {
                  value: numberOnly,
                  message: `رقم الهوية الوطنية يجب ان يكون أرقام فقط`,
                },
              }}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="standard"
                  label="رقم الهوية الوطنية ( حقل مطلوب )"
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.idNumber}
                  helperText={idNumberErrorText}
                />
              )}
            />

            <Controller
              name="ibanNumber"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="standard"
                  label="رقم حساب الآيبان ( حقل مطلوب )"
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.ibanNumber}
                  helperText={errors.ibanNumber ? 'هذا الحقل مطلوب' : ' '}
                />
              )}
            />

            <Controller
              name="mobileNumber"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="standard"
                  disabled
                  label="رقم الجوال ( حقل مطلوب )"
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.mobileNumber}
                  helperText={errors.mobileNumber ? 'هذا الحقل مطلوب' : ' '}
                />
              )}
            />

            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="standard"
                  label="البريد الإلكتروني ( حقل مطلوب )"
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.email}
                  helperText={errors.email ? 'هذا الحقل مطلوب' : ' '}
                />
              )}
            />
          </Grid>

          <LoadingButton
            sx={{ width: '100%', mt: '32px' }}
            variant="contained"
            loadingPosition="start"
            loading={isSubmitting}
            type="submit"
          >
            حفظ التغييرات
          </LoadingButton>
        </form>
      </Box>
    </Box>
  );
}
