import React from 'react';
import styled from 'styled-components';
import { height, width } from 'styled-system';
import { indeterminate, indeterminateShort } from '../theme';
import { IInfiniteLoadingBarProps } from './InfiniteLoadingBar.types';

const ProgressContainer = styled.div<
  Pick<IInfiniteLoadingBarProps, 'backgroundColor'>
>`
  background-clip: padding-box;
  background-color: #000;
  border-radius: 50px;
  display: block;
  ${height};
  overflow: hidden;
  ${width};
  position: relative;
`;

const Indeterminate = styled.div`
  background-color: ${(props) => props.theme.palette.primary.main};
  border-radius: 15px;

  &::before {
    animation: ${indeterminate} 2.25s linear infinite;
    background-color: inherit;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    top: 0;
    will-change: left, right;
  }

  &::after {
    animation: ${indeterminateShort} 1.75s linear infinite;
    animation-delay: 1.15s;
    background-color: inherit;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    top: 0;
    will-change: left, right;
  }
`;

const InfiniteLoadingBar = ({
  backgroundColor = 'background.400',
  ...props
}: IInfiniteLoadingBarProps) => (
  <ProgressContainer
    width={1}
    height="4px"
    backgroundColor={backgroundColor}
    {...props}
  >
    <Indeterminate />
  </ProgressContainer>
);

export default InfiniteLoadingBar;
