import React, { useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  Grid,
  Paper,
  Rating,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import Cookies from 'js-cookie';
import { Link, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { FilePreview } from 'components';
import { base64toFile, convertImageUrlToBase64 } from 'utilities';
import { useFetchPersonalProfile } from 'queries/profile/FetchPersonalProfile';
import NO_AVATAR from 'assests/images/deals_1.svg';
import PP_ICON from 'assests/images/deals_3.svg';
import RE_ICON from 'assests/images/deals_4.svg';
import { Login, Totp } from '../authentication';
import { PersonalInfo } from './personalInfo';
import { Deals } from './deals';
import { ProfileRoutes } from './Routes';
import { DealDetails } from './dealDetails';
import { PaymentMethods } from './paymentMethods';

export function Profile() {
  const location = useLocation();

  const [profilePictureFile, setProfilePictureFile] = useState<File>();
  const [isLoginDialogOpen, setIsLoginDialogOpen] = useState(false);
  const [isTotpDialogOpen, setIsTotpDialogOpen] = useState(false);

  const fetchPersonalProfile = useFetchPersonalProfile();

  const isUserLoggedIn = Cookies.get('token');

  const tabs = [
    {
      icon: <img src={PP_ICON} className="tap_icon" />,
      label: 'البيانات الشخصية',
      to: ProfileRoutes.personalInfo,
      component: PersonalInfo,
    },
    {
      icon: <img src={RE_ICON} className="tap_icon" />,
      label: 'الاتفاقيات',
      to: ProfileRoutes.deals,
      component: Deals,
    },
  ];

  function onCloseLoginDialog() {
    setIsLoginDialogOpen(false);
  }

  function openTotpDialog() {
    setIsTotpDialogOpen(true);
    onCloseLoginDialog();
  }

  useEffect(() => {
    if (!isUserLoggedIn) {
      setIsLoginDialogOpen(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const accountAvatar = fetchPersonalProfile.data?.data.data.avatar;

    Cookies.set(
      'accountName',
      fetchPersonalProfile.data?.data.data.name as string,
      { expires: 30 },
    );
    Cookies.set(
      'accountMobileNumber',
      fetchPersonalProfile.data?.data.data.mobileNumber as string,
      { expires: 30 },
    );
    Cookies.set(
      'accountId',
      fetchPersonalProfile.data?.data.data.id as string,
      { expires: 30 },
    );
    Cookies.set(
      'accountBalance',
      fetchPersonalProfile.data?.data.data.availableBalance?.toString() as string,
      { expires: 30 },
    );
    Cookies.set(
      'accountRate',
      fetchPersonalProfile.data?.data.data.rateNumber?.toString() as string,
      { expires: 30 },
    );

    if (accountAvatar) {
      convertImageUrlToBase64(accountAvatar || '').then(
        async (avatarBase64) => {
          const avatarFile = await base64toFile(avatarBase64, 'avatar-img');

          setProfilePictureFile(avatarFile);
        },
      );
    }
  }, [
    fetchPersonalProfile.data?.data.data.availableBalance,
    fetchPersonalProfile.data?.data.data.id,
    fetchPersonalProfile.data?.data.data.mobileNumber,
    fetchPersonalProfile.data?.data.data.name,
    fetchPersonalProfile.data?.data.data.rateNumber,
    fetchPersonalProfile.data?.data.data.avatar,
    fetchPersonalProfile.isSuccess,
  ]);

  return (
    <Paper
      className="profile_box"
      elevation={3}
      sx={{
        my: { xs: '26px', sm: 7 },
        display: 'grid',
        gridTemplateColumns: { md: '1fr', sm: '1fr' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          py: { xs: '26px', sm: 8 },
          px: 4,
        }}
        className="TapsContainer"
      >
        <Grid
          display="grid"
          gridTemplateColumns="50% 50%"
          gap={0}
          alignItems="center"
        >
          <Box
            sx={{
              display: 'flex',
              objectFit: 'contain',
            }}
          >
            <Grid
              display="grid"
              gridTemplateColumns="64px auto"
              gap={2}
              alignItems="start"
            >
              <Box
                sx={{
                  display: 'flex',
                  width: '64px',
                  height: '64px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  objectFit: 'contain',
                }}
                className="user_avatar"
              >
                {profilePictureFile ? (
                  <FilePreview file={profilePictureFile} />
                ) : (
                  <img
                    src={NO_AVATAR}
                    width="100%"
                    alt="no avatar"
                    className="no_avatar"
                  />
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  objectFit: 'contain',
                  flexDirection: 'column',
                }}
              >
                <Typography className="welcome_text">مرحباً بك</Typography>
                <Typography className="phone_number">
                  {Cookies.get('accountMobileNumber')}
                </Typography>
                <Typography className="user_rating" sx={{ mt: 1 }}>
                  <Rating
                    name="read-only"
                    value={Number(Cookies.get('accountRate'))}
                    size="small"
                    readOnly
                  />
                </Typography>
              </Box>
            </Grid>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Typography className="balance_text" sx={{ mt: 1 }}>
              الرصيد المتاح
            </Typography>
            <Typography className="balance" sx={{ mt: 1 }}>
              {Number(Cookies.get('accountBalance'))}
            </Typography>
          </Box>
        </Grid>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={location.pathname}
          className="taps_box"
          aria-label="Profile tabs"
          sx={{ borderRight: 0, '& .MuiTabs-indicator': { width: 0 } }}
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.label}
              icon={tab.icon}
              iconPosition="start"
              label={tab.label}
              value={tab.to}
              to={tab.to}
              component={Link}
              LinkComponent={tab.component}
              className="tapbox"
              sx={{
                justifyContent: 'start',
                borderRadius: '4px',
                minHeight: '48px',
                '&.Mui-selected': { backgroundColor: '#E4F7FF' },
                border: 0,
              }}
            />
          ))}
        </Tabs>
      </Box>

      <Routes>
        <Route path="/personalInfo" element={<PersonalInfo />} />
        <Route path="/deals/*" element={<Deals />} />
        <Route path="/deals/:id" element={<DealDetails />} />
        <Route path="/deals/:id/payment-methods" element={<PaymentMethods />} />

        <Route path="*" element={<Navigate to={tabs[0].to} />} />
      </Routes>

      {isLoginDialogOpen && (
        <Dialog
          open={isLoginDialogOpen}
          aria-labelledby="login-dialog"
          maxWidth="md"
        >
          <Login
            isTotpDialogOpen={isTotpDialogOpen}
            openTotpDialog={openTotpDialog}
          />
        </Dialog>
      )}

      <Dialog
        open={isTotpDialogOpen}
        aria-labelledby="totp-dialog"
        maxWidth="md"
      >
        <Totp />
      </Dialog>
    </Paper>
  );
}
