import {
  DealSummaryStep,
  OppositeAccountStep,
  IUpsertDeal,
  IUpsertDealSteps,
} from '../_common';
import { ServiceInfoStep } from './ServiceInfoStep';

export function UpsertServiceDealSteps({
  activeStep,
  onChangeActiveStep,
}: IUpsertDeal) {
  if (activeStep === IUpsertDealSteps.oppositeAccountStep) {
    return <OppositeAccountStep onChangeActiveStep={onChangeActiveStep} />;
  }

  if (activeStep === IUpsertDealSteps.dealSummaryStep) {
    return <DealSummaryStep />;
  }

  return <ServiceInfoStep onChangeActiveStep={onChangeActiveStep} />;
}
