import {
  DealSummaryStep,
  OppositeAccountStep,
  IUpsertDeal,
  IUpsertDealSteps,
} from '../_common';
import { ProductInfoStep } from './productInfoStep';

export function UpsertProductDealSteps({
  activeStep,
  onChangeActiveStep,
}: IUpsertDeal) {
  if (activeStep === IUpsertDealSteps.oppositeAccountStep) {
    return <OppositeAccountStep onChangeActiveStep={onChangeActiveStep} />;
  }

  if (activeStep === IUpsertDealSteps.dealSummaryStep) {
    return <DealSummaryStep />;
  }

  return <ProductInfoStep onChangeActiveStep={onChangeActiveStep} />;
}
