import { Box, Typography } from '@mui/material';
import FAQ_1 from 'assests/images/faq_1.svg';
import FAQ_2 from 'assests/images/faq_2.svg';
import FAQ_3 from 'assests/images/faq_3.svg';
import FAQ_4 from 'assests/images/faq_4.svg';
import FAQ_5 from 'assests/images/faq_5.svg';
import FAQ_6 from 'assests/images/faq_6.svg';
import FAQ_7 from 'assests/images/faq_7.svg';

export function Faq() {
  return (
    <Box sx={{ p: 3 }} className="faq_container">
      <img
        src={FAQ_7}
        alt=""
        style={{
          width: '100px',
        }}
      />
      <Typography fontSize="18px" fontWeight="bold" className="faq_header">
        الأسئلة الشائعة
        <img
          src={FAQ_1}
          alt=""
          style={{
            width: '100px',
          }}
        />
      </Typography>
      <dl>
        <dt className="odd">
          <Typography color="primary" fontSize="16px" fontWeight="bold">
            ماهو بنكنوت؟
          </Typography>
        </dt>
        <dd>
          <Typography fontSize="16px" sx={{ mt: 1 }}>
            بنكنوت منصة إلكترونية لتوثيق وإتمام الاتفاقات التي تتم عبر الإنترنت،
            بحيث يتم حجز المبلغ المدفوع الى حين استلام الطلب او انجاز الخدمة
            المطلوبة.
          </Typography>
          <img
            src={FAQ_2}
            alt=""
            style={{
              width: '100px',
            }}
          />
        </dd>

        <dt className="even">
          <Typography
            color="primary"
            fontSize="16px"
            fontWeight="bold"
            sx={{ mt: 2 }}
          >
            كيف بنكنوت يحميني؟
          </Typography>
        </dt>
        <dd>
          <Typography fontSize="16px" sx={{ mt: 1 }}>
            مع بنكنوت، مايحتاج تحول الى شخص غريب او تشارك بياناتك الشخصية
            والبنكية لغرض البيع والشراء انشيء اتفاقية جديدة ادفع المبلغ بكل
            آمان، راح يتم تعليق المبلغ المدفوع الى حين التأكد من استلام الطلب او
            تحقيق الاتفاقية.
          </Typography>
          <img
            src={FAQ_3}
            alt=""
            style={{
              width: '100px',
            }}
          />
        </dd>

        <dt className="odd">
          <Typography
            color="primary"
            fontSize="16px"
            fontWeight="bold"
            sx={{ mt: 2 }}
          >
            كيف أسوي اتفاقية جديدة؟
          </Typography>
        </dt>
        <dd>
          <Typography fontSize="16px" sx={{ mt: 1 }}>
            ابدأ اتفاقيتك عن طريقة تسجيل الدخول للموقع برقم الجوال، اختار انشاء
            اتفاقية جديدة، أدخل بيانات المنتج او الخدمة ومعلومات الطرف الآخر
            [البائع]، ارسل الاتفاقية الى الطرف الآخر وتتبعها بكل سهولة.
          </Typography>
          <img
            src={FAQ_4}
            alt=""
            style={{
              width: '100px',
            }}
          />
        </dd>

        <dt className="even">
          <Typography
            color="primary"
            fontSize="16px"
            fontWeight="bold"
            sx={{ mt: 2 }}
          >
            ماهي رسوم استخدام بنكنوت؟
          </Typography>
        </dt>
        <dd>
          <Typography fontSize="16px" sx={{ mt: 1 }}>
            رسوم خدمة بنكنوت هي ٤٪ من قيمة العملية، يتحملها المشتري ويتم دفعها
            مع إجمالي قيمة الاتفاقية.
          </Typography>
          <img
            src={FAQ_5}
            alt=""
            style={{
              width: '100px',
            }}
          />
        </dd>

        <dt className="odd">
          <Typography
            color="primary"
            fontSize="16px"
            fontWeight="bold"
            sx={{ mt: 2 }}
          >
            هل اقدر استخدم بنكنوت دولياً؟
          </Typography>
        </dt>
        <dd>
          <Typography fontSize="16px" sx={{ mt: 1 }}>
            بنكنوت حالياً فقط للاتفاقات داخل المملكة العربية السعودية.
          </Typography>
          <img
            src={FAQ_6}
            alt=""
            style={{
              width: '100px',
            }}
          />
        </dd>
      </dl>
    </Box>
  );
}
