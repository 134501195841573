import React from 'react';
import { Box, Button, Grid, Skeleton } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import CssBaseline from '@mui/material/CssBaseline';
import { useSnackbar } from 'notistack';
import { ICopyClipboardInputProps } from './CopyClipboardInput.types';

function CopyClipboardInput(props: ICopyClipboardInputProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { content, isLoading = false } = props;
  const shareInputFieldText: React.RefObject<HTMLInputElement> =
    React.createRef();

  function copyToClipboard(e: any) {
    if (!isLoading) {
      shareInputFieldText.current?.select();
      document.execCommand('copy');
      e.target.focus();

      enqueueSnackbar('تم نسخ الرابط');
    }
  }

  if (isLoading) {
    return (
      <Box flexDirection="column" width={1}>
        <Skeleton sx={{ mx: 2 }} variant="rounded" width="40px" />
        <Skeleton
          width={1}
          sx={{ mx: 2 }}
          variant="rectangular"
          height="40px"
        />
      </Box>
    );
  }

  return (
    <Grid width={1} display="grid" gridTemplateColumns="max-content auto">
      <CssBaseline />
      <Button
        variant="contained"
        startIcon={<ContentCopy />}
        onClick={copyToClipboard}
        sx={{
          fontSize: '14px',
          py: 1,
          backgroundColor: '#0558A9',
          borderRadius: '0 4px 4px 0',
        }}
      >
        نسخ الرابط
      </Button>
      <input
        ref={shareInputFieldText}
        type="text"
        value={content}
        color="#8D8D8D"
        style={{
          width: '100%',
          padding: '0 24px',
          backgroundColor: '#ECF5F9',
          border: 0,
          borderRadius: '4px 0 0 4px',
          fontFamily: 'Montserrat-Arabic',
          fontSize: '16px',
        }}
        readOnly
      />
    </Grid>
  );
}

export default CopyClipboardInput;
