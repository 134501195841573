import axios, { AxiosError } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { useSnackbar } from 'notistack';
import { IBankNoteError } from 'types';

export function useDoneDeal() {
  // Get QueryClient from the context
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const {
    error: err,
    data,
    isLoading,
    isSuccess,
    mutate,
    reset,
  } = useMutation({
    mutationKey: ['done-deal'],
    mutationFn: (id?: string) => {
      return axios.post(
        `${import.meta.env.VITE_API_BASE_URL}/deals/${id}/done`,
        null,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get('token')}`,
            'accept-language': 'ar',
          },
        },
      );
    },
    onSuccess: () =>
      queryClient
        .invalidateQueries({ queryKey: ['fetch-deal'] })
        .then(() =>
          queryClient.invalidateQueries({ queryKey: ['fetch-user-deals'] }),
        ),
    onError: (requestError: AxiosError<IBankNoteError>) => {
      enqueueSnackbar(
        requestError?.response?.data?.errors[0].message ||
          requestError?.response?.data?.message ||
          '',
        {
          variant: 'error',
        },
      );
    },
  });

  const error = err as AxiosError;

  return {
    error,
    data,
    isLoading,
    isSuccess,
    mutate,
    reset,
  };
}
