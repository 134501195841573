import { DropzoneProps } from 'react-dropzone';
import { BankNoteQueryStatus } from 'BankNoteQuery/Common.types';

export enum FileUploadingStatus {
  pending = 'pending',
  uploading = 'uploading',
  success = 'success',
  failed = 'failed',
}

export const FileUploadingStatusLocale = {
  [FileUploadingStatus.pending]: 'filesPending',
  [FileUploadingStatus.uploading]: 'uploading',
  [FileUploadingStatus.success]: 'uploaded',
  [FileUploadingStatus.failed]: 'failed',
};

export type IFilesUploaderProps = Pick<DropzoneProps, 'accept'> & {
  /**
   * Array of selected files
   */
  filesStatuses?: Array<FileUploadingStatus>;
  /**
   *  Component when there is no files uploaded
   *
   */
  placeholderText?: string;
  /**
   *  Component when drag event is active and still there is no files uploaded
   *
   */
  dragActiveText?: string;
  /**
   * Listener on change
   */
  onChange?: (files: Array<File>) => void;
  /**
   * Uploading status
   */
  uploadStatus?: BankNoteQueryStatus;
  /**
   * If enabled, component takes 100% of parent element's height
   *
   * @default false
   */
  fullHeight?: boolean;
  files?: File[];
};
