import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { Box, Typography } from '@mui/material';
import { AddOutlined, UploadFileOutlined } from '@mui/icons-material';
import { darkenColor } from '../styleFunctions';
import {
  IFilesUploaderProps,
  FileUploadingStatus,
} from './FilesUploader.types';
import FilePreview from './FilePreview';

const FileUploaderContainer = styled(Box)`
  display: flex;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
`;

const FilesGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  grid-template-rows: auto;
  grid-row-gap: 8px;
  grid-column-gap: 8px;
  padding: 8px;
  border: 1px solid #cbcbcb;
  border-radius: 4px;
  max-height: 400px;
  overflow-y: auto;
`;

const DropHere = styled(Box)<{ isDragActive: boolean; disabled: boolean }>`
  display: flex;
  width: 100%;
  height: 100%;
  cursor: ${(props) => !props.disabled && 'pointer'};
  background-color: #f1fafe;
  transition: border-color 100ms ease-out, background-color 100ms ease-out;
  outline: none;
  border-radius: 4px;

  &:hover {
    background-color: ${darkenColor('#f1fafe', 0.01)};
  }
`;

const UploadStatus: Record<string, FileUploadingStatus> = {
  idle: FileUploadingStatus.pending,
  loading: FileUploadingStatus.uploading,
  success: FileUploadingStatus.success,
  error: FileUploadingStatus.failed,
};

export function FilesUploader({
  filesStatuses,
  onChange,
  accept = {
    'image/jpg': ['.jpg'],
    'image/png': ['.png'],
    'image/jpeg': ['.jpeg'],
    'application/pdf': ['.pdf'],
    '.doc': ['.doc'],
    '.docx': ['.docx'],
    '.xlsx': ['.xlsx'],
  },
  uploadStatus,
  fullHeight = false,
  files: selectedFiles = [],
}: IFilesUploaderProps) {
  const [files, setFiles] = useState<File[]>(selectedFiles);
  const [statuses, setStatuses] = useState<Array<FileUploadingStatus>>([]);

  useEffect(() => {
    if (filesStatuses?.length) {
      setStatuses(filesStatuses);
    } else if (uploadStatus) {
      setStatuses(files.map(() => UploadStatus[uploadStatus]));
    }
  }, [filesStatuses, uploadStatus, files]);

  useEffect(() => {
    setFiles(selectedFiles);
  }, [selectedFiles]);

  const onDrop = (acceptedFiles: Array<File>) => {
    const newFiles = [...files, ...acceptedFiles];
    const newFilesStatuses = newFiles.map(() => FileUploadingStatus.pending);

    setFiles(newFiles);
    setStatuses(newFilesStatuses);
    onChange?.(newFiles);
  };

  function removeFile(index: number) {
    const computedFiles = [...files.slice(0, index), ...files.slice(index + 1)];
    setFiles(computedFiles);
    onChange?.(computedFiles);
  }

  const shouldDisableUpload = statuses?.some(
    (status) => status === FileUploadingStatus.uploading,
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    multiple: true,
    maxSize: 10000000,
    disabled: shouldDisableUpload,
  });

  // const supportedFileFormats = Object.keys(accept)
  //   .map((format) => {
  //     if (format.includes('/')) {
  //       return `.${format.split('/')?.[1]}`;
  //     }
  //
  //     return format;
  //   })
  //   .join(', ');

  return (
    <FileUploaderContainer height={fullHeight ? '100%' : 162} minHeight={162}>
      <input
        id="file-uploader"
        data-testid="file-uploader"
        {...getInputProps()}
      />

      {files?.length > 0 ? (
        <FilesGrid>
          <DropHere
            {...getRootProps()}
            disabled={shouldDisableUpload}
            isDragActive={isDragActive}
          >
            <Box
              width={1}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <AddOutlined fontSize="large" />
              <Typography sx={{ mt: 2 }} fontSize="14px" textAlign="center">
                اضغط هنا لرفع مزيد من الصور
              </Typography>
            </Box>
          </DropHere>

          {files?.map((file, index) => (
            <FilePreview
              key={`${file}-${index}`}
              index={index}
              file={file}
              removeFile={removeFile}
              status={statuses?.[index]}
            />
          ))}
        </FilesGrid>
      ) : (
        <DropHere
          {...getRootProps()}
          disabled={shouldDisableUpload}
          isDragActive={isDragActive}
        >
          <Box
            display="flex"
            width={1}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <UploadFileOutlined fontSize="medium" color="primary" />
            <Typography
              color="primary"
              sx={{ mt: 2, mb: 1, textDecoration: 'underline' }}
              fontSize="14px"
            >
              اضغط هنا لرفع صور
            </Typography>
            <Typography
              color="#BCBCBC"
              fontSize="14px"
              sx={{ mx: 2 }}
              textAlign="center"
            >
              اضغط هنا لرفع صور للمنتج الصيغ المسموح بها JPEG, PNG or JPG (الحد
              الأقصى للملفات 10MB)
            </Typography>
          </Box>
        </DropHere>
      )}
    </FileUploaderContainer>
  );
}
